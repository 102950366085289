import React from 'react';
import { TabBar } from 'antd-mobile'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

import {
    AppOutline,
    AddSquareOutline,
    UserOutline,
    // ShopbagOutline,
    TravelOutline,
    // PictureOutline,
} from 'antd-mobile-icons'  

const Bottom = (props) => {
// const Bottom = ({onMessage}) => {
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;
    const setRouteActive = (value) => {
      history.push(value);
      props.onPrevious(value);
      // for (const m of tabs) {
      //   if(value == m.key){
      //     onMessage(m.title);
      //   }
      // }
    };

    
    const tabs = [
      {
        key: '/asst',
        title: '助理',
        icon: <AppOutline  />,
      },
      {
        key: '/create-asst',
        title: '创建',
        icon: <AddSquareOutline />,
      },
      {
        key: '/asst-square',
        title: '探索',
        icon: <TravelOutline />,
      },
      // {
      //   key: '/asst-img',
      //   title: '绘画',
      //   icon: <PictureOutline />,
      // },
      {
        key: '/me',
        title: '我的',
        icon: <UserOutline />,
      },
    ];
  
    // const handleOnPress = (title) =>{
    //   onMessage(title);
    // };
  
    // const handleTabClick = (tab) => {
    //   alert(tab);
    // }
  
    return (
      <div>
  
      <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
        {tabs.map(item => (
          <TabBar.Item 
          key={item.key} 
          icon={item.icon} 
          title={item.title} 
          />
        ))}
      </TabBar>
      </div>
    );
}

export default Bottom;