import React,{useEffect,useState} from "react";
import { NavBar, Toast, Space, DotLoading, } from 'antd-mobile'
import { StarFill, StarOutline,DownlandOutline, } from 'antd-mobile-icons'
import Chat,{ Bubble, useMessages, Notice, Icon, } from "@chatui/core";    
import { useHistory,useLocation } from "react-router-dom";

import 'highlight.js/styles/docco.css'	
import './user-gpt.css'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
const wssUrl = process.env.REACT_APP_WWS_URL;
const baseApiLafUrl = process.env.REACT_APP_BASE_API_LAF_URL;
const sensitiveWords = JSON.parse(process.env.REACT_APP_IS_SENSITIVE);//过滤词开关，打开true,关闭false
const isCache = JSON.parse(process.env.REACT_APP_IS_CACHE);//缓存开关，打开true,关闭false
const isLaravel = JSON.parse(process.env.REACT_APP_IS_LARAVEL);
let wss = null;
let lockReconnect = false;  //避免ws重复连接

const headimgurl = localStorage.getItem('headimgurl');
const converter = new showdown.Converter(); // eslint-disable-line no-undef
converter.setOption("tables",true);

let initialMessages = [
  {
    type: "notice",
    content: { text: "受限于光速，响应较慢属于正常现象" },
  },
];

// const defaultQuickReplies = [
//   {
//     icon: "message",
//     name: "联系人工服务",
//     isNew: true,
//     isHighlight: true,
//   },
// ];

const token = localStorage.getItem('accessToken');
let page = 1;
let isRefresh = true;
// let isEndReply = false;
let firstReply = true;
let did = '';
let replayStr = '';
let replayStrIndex = 0;
let replayStrProcess = '';
let replyCheck = {};
let heartCheck = {};
let isReconnect = false;



const UserGPT = (props) => {
  const [isExecuted, setIsExecuted] = useState(false);
  const [isPrint, setIsPrint] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const location = useLocation();
  const aid = location.state?.aid;
  const { messages, appendMsg, updateMsg, setTyping, deleteMsg, prependMsgs } = useMessages(initialMessages);



  if(!isExecuted){
    heartCheck = {
      timeout: 20*1000,        //1分钟发一次心跳
      timeoutObj: null,
      serverTimeoutObj: null,
      reset: function(){
          clearTimeout(this.timeoutObj);
          clearTimeout(this.serverTimeoutObj);
          return this;
      },
      start: function(){
          var self = this;
          this.timeoutObj = setTimeout(function(){
              //这里发送一个心跳，后端收到后，返回一个心跳消息，
              //onmessage拿到返回的心跳就说明连接正常
              sendMessageSocket('ping',{});
              console.log("=========>>>ping!")
              self.serverTimeoutObj = setTimeout(function(){//如果超过一定时间还没重置，说明后端主动断开了
                  wss.close();     //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
              }, 8000)
          }, this.timeout)
      }
    };

    replyCheck = {
      timeout: 70, //字符出输出速度
      complete: 1000,//回复完毕后检查是否输出完毕执行时间
      timeoutObj: null,
      completeObj:null,
      reset: function(){
        firstReply = true;
        did = '';
        replayStr = '';
        replayStrIndex = 0;
        replayStrProcess = '';
        clearInterval(this.timeoutObj);
        clearInterval(this.completeObj);
        return this;
      },
      startComplete: function(dataGpt,dataMe){
        var self = this;
      
        this.completeObj = setInterval(() => {
          // console.log('-------',replayStr);
          if(replayStrProcess === replayStr){

            clearInterval(self.timeoutObj);
            clearInterval(self.completeObj);

            const regex = /\|.*\|/g;
            const matches = replayStr.match(regex);
            let isTable = 0;
            if (matches) {isTable = 1;}
            // console.log('xxxxxxxxxxxxxxxxx================>',sensitiveWords);
            //过滤词开关
            if(sensitiveWords){

              //这里对接过滤词接口
              const url = `${baseApiUrl}api/sensitive_words`;
              const postData = {
                did: did,
                text: replayStr,
                token: token,
              }
              // console.log(JSON.stringify(postData));
              fetch(url, {
                method: 'POST',
                body: JSON.stringify(postData),
              })
              .then(res => res.json())
              .then(res => {
                const { code, msg, data} = res;
                if(code === 1){

                  updateMsg(did,{
                    _id: did,
                    type: "html",
                    content: { text: converter.makeHtml(data.text), isCollect: 0, aid: aid, isTable: isTable },
                    user: { avatar: "/ai.png" },
                  }); 

                  let dialogueId = did;
                  replayStr = '';
                  did = '';
                  replayStrIndex = 0;
                  replayStrProcess = '';
                  firstReply = true;
                  setIsPrint(true);

                  if(!isLaravel){
                    const updateAnswerUrl = `${baseApiLafUrl}answer-update`;
                    const postUpdateAnswerData = {
                      did: dialogueId,
                      text: data.text
                    }
                    // console.log('=========>',JSON.stringify(postUpdateAnswerData));
                    //请求云函数接口创建用户助理
                    fetch(updateAnswerUrl, {
                      method: 'POST',
                      body: JSON.stringify(postUpdateAnswerData),
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                      }
                    })
                    .then(res => res.json())
                    .then(res => {
                      const { code, msg } = res;
                      if(code === 1){
                      }else{
                        Toast.show({icon: 'fail',content: msg});
                      }
                    })
                    .catch(error => console.error(error));
                  }

                  dataGpt.text = data.text;
                  if(isCache){
                    cacheAnswer(dataGpt,dataMe,isTable);
                  }

                }

                if(code < 0){
                  // Toast.show({icon: 'fail',content: msg});
                  console.log( '请求过滤接口异常:50003',msg );

                  updateMsg(did,{
                    _id: did,
                    type: "html",
                    content: { text: converter.makeHtml(replayStr), isCollect: 0, aid: aid, isTable: isTable },
                    user: { avatar: "/ai.png" },
                  }); 
                  // return;

                  replayStr = '';
                  did = '';
                  replayStrIndex = 0;
                  replayStrProcess = '';
                  firstReply = true;
  
                  setIsPrint(true);

                  if(isCache){
                    cacheAnswer(dataGpt,dataMe,isTable);
                  }
                }
              })
              .catch(error => {

                console.error('请求过滤接口异常:50001',error);
                // Toast.show({
                //   icon: 'fail',
                //   content: '请求过滤接口异常:50001',
                // });
                updateMsg(did,{
                  _id: did,
                  type: "html",
                  content: { text: converter.makeHtml(replayStr), isCollect: 0, aid: aid, isTable: isTable},
                  user: { avatar: "/ai.png" },
                }); 

                replayStr = '';
                did = '';
                replayStrIndex = 0;
                replayStrProcess = '';
                firstReply = true;

                setIsPrint(true);

                if(isCache){
                  cacheAnswer(dataGpt,dataMe,isTable);
                }

              });
            }else{

              if(isCache){//缓存开关
                cacheAnswer(dataGpt,dataMe,isTable);
              }

              updateMsg(did,{
                _id: did,
                type: "html",
                content: { text: converter.makeHtml(replayStr), isCollect: 0, aid: aid, isTable: isTable },
                user: { avatar: "/ai.png" },
              });

              replayStr = '';
              did = '';
              replayStrIndex = 0;
              replayStrProcess = '';
              firstReply = true;

              setIsPrint(true);
              // clearInterval(self.completeObj);
            }
           

          }
        }, this.complete);
      },
      startProcess: function(){

        // var self = this;
        this.timeoutObj = setInterval(() => {
          // console.log('=====');
          // console.log('------->',replayStr,did);
           if(replayStr !== ''){
              if(replayStr[replayStrIndex]){
                replayStrProcess = replayStrProcess + replayStr[replayStrIndex];
                replayStrIndex++;
                if(firstReply){
                  firstReply = false;
                  appendMsg({
                    _id: did,
                    type: "html",
                    content: { text: converter.makeHtml(replayStrProcess) },
                    user: { avatar: "/ai.png" },
                  });
                }else{
                  updateMsg(did,{
                    _id: did,
                    type: "html",
                    content: { text: converter.makeHtml(replayStrProcess) },
                    user: { avatar: "/ai.png" },
                  });  
                }
              }
           }
        }, this.timeout);

      }
    }
  }
  

  function cacheAnswer(dataGpt,dataMe,isTable) {

    let cacheAnswerUrl = '';
    if(isLaravel){
      cacheAnswerUrl = `${baseApiUrl}api/wx/cache-answer`;
    }else{
      cacheAnswerUrl = `${baseApiUrl}api/cache-answer`;
    }
    //   postDataToJson.text = postDataToJson.text.replace(/\n/g, '\\n');
    const cacheAnswerData = {
      id: dataGpt.id,
      aid: dataGpt.aid,
      text: dataGpt.text,
      isCollect: 0,
      isTable: isTable,
      created: dataGpt.created,
      isMe: 2,
      parentMessageId: dataGpt.parentMessageId,
      mid: dataMe.id,
      mtext: dataMe.text,
      mcreated: dataMe.created,
      misMe: 1,
      token: token
    }
    //请求云函数接口创建用户助理
    fetch(cacheAnswerUrl, {
      method: 'POST',
      body: JSON.stringify(cacheAnswerData),
    }).catch(error => console.error(error)); 
    
    
  }


  const history = useHistory();
  
  // console.log('isReconnect===>=====>',isReconnect);

  const back = () => {
    // console.log('isReconnect===>cccc===>',isReconnect);
    page = 1;
    if(replyCheck.timeout){
      // console.log(replyCheck);
      replyCheck.reset();
    }
    if(heartCheck.timeout){
      heartCheck.reset();
    }
    
    props.onBottom(true);
    props.onHelper(true);
    isReconnect = false;
    // console.log('isReconnect===>dddd===>',isReconnect);
    if(wss){
      wss.close();
    }
    history.push('/asst');
  }

  async function handleSend (type, val) {
    if(isPrint){
      // console.log('dddddddddddd==>>',{accessToken: token, aid: aid, text: val.trim()});

      if (type === "text" && val.trim()) {

        appendMsg({
          type: "text",
          content: { text: val.trim() },
          position: "right",
          user: {avatar: headimgurl }
        });
        setTyping(true);

        //请求laravel接口-添加提问信息至explore_user_dialogue表
        if(isLaravel){
          let url = baseApiUrl+'api/wx/ask-user-dialogue';
          const postData = {
            aid: aid,
            text: val.trim(),
            token: token
          };
          fetch(url,{
            method: 'POST',
            body: JSON.stringify(postData),      
          }).then(res => res.json())
          .then(res => {
            const { code, msg, data} = res;

            if(code === 1){
              console.log("askv2-param====>",{ accessToken: token, aid: aid, text: val.trim(), parentMessageId: data.parentMessageId, askId: data.askId, answerId: data.answerId, parentMessages: data.parentMessages });
              sendMessageSocket('askv2',{ accessToken: token, aid: aid, text: val.trim(), parentMessageId: data.parentMessageId, askId: data.askId, answerId: data.answerId, parentMessages: data.parentMessages });
              replyCheck.reset().startProcess();
              setIsPrint(false);


            }else if(code === 501){
              Toast.show({
                icon: 'fail',
                content: '登录过期',
              });
              localStorage.clear();
              setTimeout(() => {
                window.location.href = '/';
              }, 1000);
              return;
            }else{
              Toast.show({icon: 'fail',content: msg,});
            }
          })
          .catch(error => console.error(error));

        }else{
          // sendMessage(val.trim());
          // sendMessageToApiCallBak(val.trim());
          // console.log({accessToken: token, aid: aid, text: val.trim()});
          sendMessageSocket('ask',{accessToken: token, aid: aid, text: val.trim()});
          replyCheck.reset().startProcess();
          setIsPrint(false);
        }

      }
    }else{

    }

  }

  // function handleQuickReplyClick (item) {
  //   setTyping(true);
  //   setTimeout(() => {
  //     appendMsg({
  //       type: "text",
  //       content: { text: "请微信联系郜小宅：gaop100" },
  //       user: { avatar: "/system.png" },
  //     });
  //   }, 1000);
  // }

  function downloadTableToFile(_id, type, content, user){
    
    updateMsg(_id,{
      _id: _id,
      type: type,
      content: { text: converter.makeHtml(content.text),isCollect: content.isCollect, aid: content.aid, isTable: 2 },
      user: user,
    });

    let url = '';
    if(isLaravel){
      url = `${baseApiUrl}api/wx/download-table`;
    }else{
      url = `${baseApiUrl}api/download-table`;
    }
    // const url = `${baseApiUrl}api/download-table`;

    const postData = {
      did: _id,
      aid: content.aid,
      token: token,
    }

    // console.log(postData);return;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(postData),
    })
    .then(res => res.json())
    .then(res => {

      updateMsg(_id,{
        _id: _id,
        type: type,
        content: { text: converter.makeHtml(content.text),isCollect: content.isCollect,aid: content.aid, isTable: 1 },
        user: user,
      });


      const { code, msg, data} = res;
      // console.log(res);
      if(code === 1){
        window.location.href = data.fileurl;
        return;
      }

      if(code < 0){
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        return;
      }

   

    })
    .catch(error => {
      console.error(error);
      Toast.show({
        icon: 'fail',
        content: '网络请求异常,请稍后再试',
      });
    });

  }

  function updateMsgToIsCollect(_id, type, content, user, isCollect){

    updateMsg(_id,{
      _id: _id,
      type: type,
      content: { text: converter.makeHtml(content.text),isCollect: 2, aid: content.aid, isTable: content.isTable, aname: content.aname },
      user: user,
    });


    if(isCache){
      let url = '';
      if(isLaravel){
        url = baseApiUrl+'api/wx/collect-user-dialogue';
      }else{
        url = baseApiUrl+'api/collect-user-dialogue';
      }
      // const url = baseApiUrl+'api/collect-user-dialogue';
      const postData = {
        id: _id,
        isCollect: isCollect,
        aid: content.aid,
        aname: content.aname,
        text: content.text,
        token: token
      };

      fetch(url,{
        method: 'POST',
        body: JSON.stringify(postData),      
      }).then(res => res.json())
      .then(res => {
        const { code, msg } = res;
        // console.log(res);
        if(code === 1){
  
          if(isCollect === 1){
            Toast.show({content: '已收藏'});
          }else{
            Toast.show({content: '取消收藏'});
          }
  
          setTimeout(() => {
            updateMsg(_id,{
              _id: _id,
              type: type,
              content: { text: converter.makeHtml(content.text),isCollect: isCollect, aid: content.aid, isTable: content.isTable, aname: content.aname },
              user: user,
            });
          }, 300);        
        }
        // console.log("================>",code);
        if(code === 0 || code === 501){
          Toast.show({
            icon: 'fail',
            content: msg,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.href = '/';
          }, 1000);
          return;
        }
  
        if(code < 0){
          Toast.show({
            icon: 'fail',
            content: msg,
          });
          return;
        }
    
      })
      .catch(error => console.error(error));






    }else{
      const url = `${baseApiLafUrl}collect-user-dialogue?id=${_id}&isCollect=${isCollect}&aid=${content.aid}`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(res => {
        const { code, msg } = res;
        // console.log(res);
        if(code === 1){
  
          if(isCollect === 1){
            Toast.show({content: '已收藏'});
          }else{
            Toast.show({content: '取消收藏'});
          }
  
          setTimeout(() => {
            updateMsg(_id,{
              _id: _id,
              type: type,
              content: { text: converter.makeHtml(content.text),isCollect: isCollect, aid: content.aid, isTable: content.isTable  },
              user: user,
            });
          }, 300);        
        }
  
        if(code === 0){
          Toast.show({
            icon: 'fail',
            content: msg,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.href = '/';
          }, 1000);
          return;
        }
  
        if(code < 0){
          Toast.show({
            icon: 'fail',
            content: msg,
          });
          return;
        }
    
      })
      .catch(error => console.error(error));
    }
  }

  function renderMessageContent({ type, content, _id, user }) {
    // 根据消息类型来渲染
    switch (type) {
      case "text":
        return <Bubble content={content.text} />;
      case "error":
        return (
          <Bubble content={content.text}>
            <Icon
              onClick={reSend}
              type="refresh"
              className="btn-refresh"
            />
          </Bubble>
        );
      case "html":
        return (
          <Bubble>
          <div>
            <div dangerouslySetInnerHTML={{ __html: content.text }} ></div>
          
            <div style={{ fontSize: 24,textAlign: 'right' }}>

            { content.isTable === 2 &&
              <span style={{ fontSize: 14,paddingRight: '4vh' }}>
                  <DotLoading color='primary' />
              </span>    
            }

            { content.isTable === 1 &&
              <DownlandOutline 
                color='var(--adm-color-primary)' 
                style={{ fontSize: 24,paddingRight: '4vh' }}
                onClick={()=>{
                  downloadTableToFile(_id, type, content, user);
                }}
              />
            }

              
              { content.isCollect === 1 &&
              <StarFill color='var(--adm-color-primary)' onClick={()=>{
                updateMsgToIsCollect(_id, type, content, user, 0);
              }}/>
              }

              { content.isCollect === 2 &&
              <span style={{ fontSize: 14 }}>
                  <DotLoading color='primary' />
              </span>    
              }

              { content.isCollect === 0 &&
              <StarOutline color='var(--adm-color-primary)' onClick={()=>{
                updateMsgToIsCollect(_id, type, content, user, 1);
              }}/>
              } 
      
            </div>
          </div>
          </Bubble>
          
        );
      case "notice":
        return (
          <Notice
            content={content.text}
            onClose={deleteMsg.bind(this, _id)}
          />
        );
      default:
        return null;
    }
  }

  function reSend () {
    for (const m of messages) {
      if (m.position === "right") {
        handleSend("text", m.content?.text);
        break
      }
    }
  }

  function hanldeCacheRefresh(){
    if(!isRefresh){
      Toast.show({content: '没有更多消息了'});
      return;
    }

    setIsRefreshLoading(true);

    let url = '';
    if(isLaravel){
      url = baseApiUrl+'api/wx/get-user-dialogue';
    }else{
      url = baseApiUrl+'api/get-user-dialogue';
    }
    // const url = baseApiUrl+'api/get-user-dialogue';
    // console.log(url);
    const postData = {
      page: page,
      aid: aid,
      token: token
    };

    fetch(url,{
      method: 'POST',
      body: JSON.stringify(postData),      
    })
    .then(res => res.json())
    .then(res => {
      setIsRefreshLoading(false);
      const { code, msg, data} = res;
      // console.log(res);
      if(code === 1){
        page = page + 1;
        if(data.length > 0){
          let historyMsgs = [];
          for (const m of data) {
            if(m.isMe === 1){
              historyMsgs.push({
                _id: m.id,
                type: "text",
                content: { text: m.msg },
                // createdAt: m.created,
                // hasTime: true,
                position: "right",
              });
            }else{
              historyMsgs.push({
                _id: m.id,
                type: "html",
                content: { text: converter.makeHtml(m.msg), isCollect: m.isCollect, aid: m.aid, isTable: m.isTable },
                // createdAt: m.created,
                // hasTime: true,
                user: { avatar: "/ai.png" },
              });
            }
          }
          prependMsgs(historyMsgs);
          
        }else{
          isRefresh = false;
          Toast.show({content: '没有更多消息了'});
        }

      }else{
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
        
      }
    })
    .catch(error => console.error(error));


  }

  function hanldeRefresh(){
    
    if(!isRefresh){
      Toast.show({content: '没有更多消息了'});
      return;
    }

    setIsRefreshLoading(true);

    const url = `${baseApiLafUrl}get-user-dialogue?page=${page}&aid=${aid}`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(res => res.json())
    .then(res => {
      setIsRefreshLoading(false);
      const { code, msg, data} = res;
      // console.log(res);
      if(code === 1){
        page = page + 1;
        if(data.length > 0){
          let historyMsgs = [];
          for (const m of data) {
            if(m.isMe === 1){
              historyMsgs.push({
                _id: m.id,
                type: "text",
                content: { text: m.msg },
                // createdAt: m.created,
                // hasTime: true,
                position: "right",
              });
            }else{
              historyMsgs.push({
                _id: m.id,
                type: "html",
                content: { text: converter.makeHtml(m.msg), isCollect: m.isCollect, aid: m.aid, isTable: m.isTable },
                // createdAt: m.created,
                // hasTime: true,
                user: { avatar: "/ai.png" },
              });
            }
          }
          prependMsgs(historyMsgs);
          
        }else{
          isRefresh = false;
          Toast.show({content: '没有更多消息了'});
        }

      }else{
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
        
      }
    })
    .catch(error => console.error(error));
  }

  async function userGptCacheHandler(){
    setIsExecuted(true);
    page = 1;
    isRefresh = true;
    let url = '';
    if(isLaravel){
       url = baseApiUrl+'api/wx/get-user-dialogue';
    }else{
       url = baseApiUrl+'api/get-user-dialogue';
    }
    // url = baseApiUrl+'api/get-user-dialogue';
    const postData = {
      page: page,
      aid: aid,
      token: token
    };

    fetch(url,{
      method: 'POST',
      body: JSON.stringify(postData),      
    })
    .then(res => res.json())
    .then(res => {
      const { code, msg, data} = res;
      // console.log(data);
      if(code === 1){
        page++;
        if(data.length > 0){
          // console.log(data);
          for (const m of data) {
            if(m.isMe === 1){
              appendMsg({
                _id: m.id,
                type: "text",
                content: { text: m.msg },
                position: "right",
                // createdAt: m.created,
                // hasTime: true,
                user: {avatar: headimgurl }
              });
            }else{
              appendMsg({
                _id: m.id,
                type: "html",
                content: { text: converter.makeHtml(m.msg),isCollect: m.isCollect, aid: m.aid, isTable: m.isTable, aname: props.name },
                // createdAt: m.created,
                // hasTime: true,
                user: { avatar: "/ai.png" },
              });

            }
          }
        }else{
          isRefresh = false;
        }
        setIsLoading(false);

      }else{
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      }
    })
    .catch(error => console.error(error));    


  }
  
  async function userGptHandler(){

    setIsExecuted(true);
    page = 1;
    isRefresh = true;

    const url = `${baseApiLafUrl}get-user-dialogue?page=${page}&aid=${aid}`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(res => res.json())
    .then(res => {
      const { code, msg, data} = res;
      console.log(data);
      if(code === 1){
        page++;
        if(data.length > 0){
          // console.log(data);
          for (const m of data) {
            if(m.isMe === 1){
              appendMsg({
                _id: m.id,
                type: "text",
                content: { text: m.msg },
                position: "right",
                // createdAt: m.created,
                // hasTime: true,
                user: {avatar: headimgurl }
              });
            }else{
              appendMsg({
                _id: m.id,
                type: "html",
                content: { text: converter.makeHtml(m.msg),isCollect: m.isCollect, aid: m.aid, isTable: m.isTable },
                // createdAt: m.created,
                // hasTime: true,
                user: { avatar: "/ai.png" },
              });

            }
          }
        }else{
          isRefresh = false;
        }
        setIsLoading(false);

      }else{
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      }
    })
    .catch(error => console.error(error));
  }

  const handleStartRecording  = async()=>{
    // console.log('开始录音........');
    // props.wxObj.startRecord();
    props.wxObj.startRecord({
      success: function() {
      },
      fail: function(res) {
      }
    });
  }

  const handleStopRecording = async()=>{
    // 停止录音
    props.wxObj.stopRecord({
      success: function(res) {
        var localId = res.localId;
        props.wxObj.translateVoice({
          localId: localId, 
          isShowProgressTips: 1, // 默认为1，显示进度提示
          success: function (res) {
            // Toast.show({content: res.translateResult});
            if(res.translateResult){
              handleSend ('text', res.translateResult); 
            }
          }
        });
      },
      fail: function(res) {
      }
    });
  }

  function createWebSocket(url){
    console.log('to create websocket');
    try{
      if('WebSocket' in window){
          wss = new WebSocket(wssUrl);
      }
      initEventHandle();
    }catch(e){
        console.log(e);
        Toast.show({content:"网络异常请稍后再试!"});
    }
  }

  const sendMessageSocket = (requestType,requestData) => {
    const message = JSON.stringify({ type: requestType, data: requestData });
    console.log('wss-state=>',wss.readyState);
    if(wss.readyState === WebSocket.OPEN){
      console.log('readyState=========================>',requestType,requestData);
      wss.send(message);
    }else{
      setTyping(false);
      console.log('========================>connect not start');
      // Toast.show({content:"网络异常,请返回助理列表,重新进入聊天界面"});
      wss.close();
    }
    
  };

  function initEventHandle(){

    wss.onopen = (socket) => {
      console.log("connected");
      sendMessageSocket('connect',{ "accessToken": token });
    };
    
    wss.onmessage = (event) => {//如果获取到消息，心跳检测重置
      heartCheck.reset().start();//拿到任何消息都说明当前连接是正常的
      // console.log("收到了新的信息......");
      // console.log(event.data);
      const dataJson = JSON.parse(event.data);
      console.log("收到了新的信息......",dataJson.actionType);

      if(dataJson.actionType === 'error'){
        console.log('actionType================>error');
        // console.log('actionType================>error',dataJson);
        Toast.show({content:dataJson.msg});
        setTyping(false);
      }

      if(dataJson.actionType === 'connectInit'){
        setIsPrint(true);
        console.log('actionType================>connectInit');
      }

      if(dataJson.actionType === 'replyProcess'){
        replayStr = dataJson.data.text;
        did = dataJson.data.id;
        //localStorage.setItem("replyProcess", dataJson.data.text);
      }

      if(dataJson.actionType === 'replyEnd'){
        console.log('actionType================>replyEnd');
        replayStr = dataJson.data.text;
        replyCheck.startComplete(dataJson.data,dataJson.dataMe);
        props.onAnswer(dataJson.data.aid,dataJson.dataMe.text);

      }

      if(dataJson.actionType === 'pong'){
        console.log('actionType================>pong');
      }

      if(dataJson.actionType === 'testReturnV2'){
        console.log('actionType================>testReturn');
        console.log('code====================>',dataJson.code);
        console.log('data====================>',dataJson.data);
      }

    };

    wss.onclose = () => {
      console.log("closed=========>",isReconnect);
      if(isReconnect){
        // console.log("................");
        reconnect(wssUrl,'onclose');
        
      }

      // reconnect(wssUrl,'onclose');
    };

    wss.onerror = function () {
      // wss.close();
      console.log("bbbbbbbbbbbbbbbbb==========>wss连接错误!");
      setTyping(false);
      Toast.show({content:"网络异常,请返回助理列表,重新进入聊天界面"});
    };
  
  }


  //重新连接
  function reconnect(url,str) {
    // console.log('========================>reconnect!!!!====>',str);
    if(lockReconnect) return;
    lockReconnect = true;
    setTimeout(function () {     //没连接上会一直重连，设置延迟避免请求过多
        createWebSocket(url);
        lockReconnect = false;
    }, 3000);
  }

  useEffect(() => {
    if (!isExecuted) {
      isReconnect = true;
      if(isCache){
        userGptCacheHandler(); 
      }else{
        userGptHandler(); 
      }
      
      createWebSocket(wssUrl);
    }
    document.querySelectorAll('pre code').forEach((el) => hljs.highlightElement(el)) // eslint-disable-line no-undef
  });

  return (
    <div className="app">
      <div className="top">
          <NavBar onBack={back}>{props.name}</NavBar>
          { isRefreshLoading && 
          <div style={{ color: '#1677ff', textAlign: 'center', marginBottom: '1vh'}}>
            <DotLoading color='currentColor' />
            <span>加载中</span>
          </div>
          }
      </div> 
   
      <div style={{ width: "100%", height: "93%", marginTop: '3.3em' }}>
      { !isLoading && 
      <Chat
        messages={messages}
        renderMessageContent={renderMessageContent}
        // quickReplies={defaultQuickReplies}
        // onQuickReplyClick={handleQuickReplyClick}
        onSend={handleSend}
        onRefresh={isCache ? hanldeCacheRefresh : hanldeRefresh}
        recorder={{
          canRecord: true,
          onStart: handleStartRecording,
          onEnd: handleStopRecording,
        }}
      />
      }

      { isLoading &&
      <div className="loading-parent">
          <Space direction='horizontal' justify='center' align='center' wrap block style={{ '--gap': '16px' }}>
            <span style={{ fontSize: 24 }}>
              <DotLoading color='primary' />
            </span>
          </Space>
      </div>
      }
      </div>
    </div>
  );
}

export default UserGPT;