import React,{useState } from 'react';
import {
  Form,
  Input,
  Button,
  Toast, 
} from 'antd-mobile'
import { useHistory } from "react-router-dom";

const isCache = JSON.parse(process.env.REACT_APP_IS_CACHE);//缓存开关，打开true,关闭false
const baseApiLafUrl = process.env.REACT_APP_BASE_API_LAF_URL;
const baseApiUrl = process.env.REACT_APP_BASE_API_URL; 
const isLaravel = JSON.parse(process.env.REACT_APP_IS_LARAVEL);

const token = localStorage.getItem('accessToken');

const CreateAssistant = (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onFinish = (values) => {

    if(props.assistantCount === 5){
      Toast.show({
        icon: 'success',
        content: '最多可以创建5个助理!',
      });
      return;
    }

    setLoading(true);

    if(isCache){
      let url = '';
      if(isLaravel){
        url = `${baseApiUrl}api/wx/create-asssistant`;
      }else{
        url = `${baseApiUrl}api/create-asssistant`;
      }
      const postData = {
        name: values.name,
        token: token
      };

      fetch(url,{
        method: 'POST',
        body: JSON.stringify(postData),      
      })
      .then(res => res.json())
      .then(res => {
        setLoading(false);
        const { code, msg, data} = res;
  
        if(code < 0){
          Toast.show({
            icon: 'fail',
            content: msg,
          })
        }
  
        if(code === 1){
          // console.log('data==>',data);
          Toast.show({
            icon: 'success',
            content: '创建成功',
          });
          props.onMessage(data);
          history.push("/asst");
        }
  
      })
      .catch(error => console.error(error));


    }else{
      const url = `${baseApiLafUrl}create-asssistant?name=${values.name}`;
      //请求云函数接口创建用户助理
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(res => {
        setLoading(false);
        const { code, msg, data} = res;
  
        if(code < 0){
          Toast.show({
            icon: 'fail',
            content: msg,
          })
        }
  
        if(code === 1){
          // console.log('data==>',data);
          Toast.show({
            icon: 'success',
            content: '创建成功',
          });
          props.onMessage(data);
          history.push("/asst");
        }
  
      })
      .catch(error => console.error(error));
    }
   

  }

  return (
    <div>

      <Form
      onFinish={onFinish}
        layout='horizontal'
        footer={
          <Button block loading={loading} loadingText='正在提交' type='submit' color='primary' size='large' >
            提交
          </Button>
        }
      >
        <Form.Header>创建助理</Form.Header>
        <Form.Item
          name='name'
          label='名称'
          rules={[{ required: true, message: '名称不能为空' }]}
        >
          <Input  placeholder='请输入助理名称' />
        </Form.Item>
      </Form>

    </div>
  );
}

export default CreateAssistant;

