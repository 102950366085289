import { useState,useEffect } from "react";
import {
  Route,
  Switch,
  MemoryRouter as Router,
  Link,
} from 'react-router-dom'
import { Toast, FloatingBubble } from 'antd-mobile'
import {MessageFill ,} from 'antd-mobile-icons';
import wx from 'weixin-js-sdk';
import './home.css'

import UserAssistant from '../../components/UserAssistant'
import CreateAssistant from '../../components/CreateAssistant'
import PersonalCenter from "../../components/PersonalCenter";
import Bottom from '../../components/Bottom';
import UserGPT from '../../components/UserGPT';
import UserCollect from '../../components/UserCollect';
import DetailsCollect from '../../components/DetailsCollect';
import HelperAssistant from '../../components/HelperAssistant';
import UserInvitation from '../../components/UserInvitation';
import SystemGPT from '../../components/SystemGPT';
import SquareAssistant from '../../components/SquareAssistant';
// import UserRenewal from '../../components/UserRenewal';
// import MjDrawImage from '../../components/MjDrawImage';

// import PersonalTask from '../../components/PersonalTask';
// console.log('ddddddddd======>',process.env.REACT_APP_IS_SENSITIVE);


const isCache = JSON.parse(process.env.REACT_APP_IS_CACHE);//缓存开关，打开true,关闭false
const isLaravel = JSON.parse(process.env.REACT_APP_IS_LARAVEL);
const token = localStorage.getItem('accessToken');
const userSignId = localStorage.getItem('userSignId');
const baseUrl = process.env.REACT_APP_BASE_URL; 
const baseApiUrl = process.env.REACT_APP_BASE_API_URL; 
const baseApiLafUrl = process.env.REACT_APP_BASE_API_LAF_URL;

let isExecuted = false;


const Home = () => {
  const [assistantLists, setAssistantLists] = useState([]);
  // const [userSign, setUserSign] = useState({});
  const [wxObj, setWxObj] = useState(null);
  const [isShowBottom, setIsShowBottom] = useState(true);
  const [isShowHelper, setIsShowHelper] = useState(false);
  const [assistantName, setAssistantName] = useState(null);
  const [collectDetailsText, setCollectDetailsText] = useState(null);
  const [previousRoute, setPreviousRoute] = useState(null);
  const [isUserAssistantLoading, setIsUserAssistantLoading] = useState(true);
  const [assistantCount, setAssistantCount] = useState(0);

  // const [refreshTask, setRefreshTask] = useState(false);

  // console.log('--------->',userSign);

  const handleIsShowAssistant = (data) =>{
      assistantLists.unshift(data);
      setAssistantLists(assistantLists);
      setAssistantCount(assistantLists.length);
  }

  const hanldeOnBottom = (res) =>{
    setIsShowBottom(res);
  }

  const hanldeOnHelper = (res) =>{
    setIsShowHelper(res);
  }

  // const hanldeOnSignClock = (res) =>{
  //   userSign.signClock = res;
  //   userSign.nos +=3;
  //   setUserSign(userSign);
  // }
  
  // const hanldeOnSignShare = (res) =>{
  //   userSign.signShare = res;
  //   userSign.nos +=3;
  //   setUserSign(userSign);
  // }
  

  const hanldeOnPrevious = (res) =>{
    setPreviousRoute(res);
  }

  const hanldeOnCollectDetails =(res) =>{
    setCollectDetailsText(res);
  }

  const hanldeShowName = (name) =>{
    setAssistantName(name);
  }

  const handleUpdateAssistantLists =(id) =>{
    const filerAssistant = assistantLists.filter(obj => obj.id !== id);
    setAssistantLists(filerAssistant);
    setAssistantCount(filerAssistant.length);

  }

  const handleChangeFromEdit =(aid,assName) =>{
    const targetObj = assistantLists.filter(obj => obj.id === aid)[0];
    targetObj.name = assName;
    setAssistantLists(assistantLists);
  }

  const handleOnAnswer =(aid,description)=>{
    const targetObj = assistantLists.filter(obj => obj.id === aid)[0];
    targetObj.description = description;
    setAssistantLists(assistantLists);
  }

  async function showHelper(){
      // Toast.show({content: '我是助手小蜜'});
      setIsShowBottom(false);
      setIsShowHelper(false);
  }

  async function homeCacheHandler(){

    isExecuted = true;
    let url = '';

    if(isLaravel){
      url = baseApiUrl+'api/wx/get-assistant';
    }else{
      url = baseApiUrl+'api/get-assistant';
    }

    const postData = {token: token};
    fetch(url,{
      method: 'POST',
      body: JSON.stringify(postData),      
    })
    .then(res => res.json())
    .then(res => {

      const { code, msg, data} = res;
      console.log(res);
      if(code === 1){
        setAssistantLists(data);
        setIsUserAssistantLoading(false);
        setAssistantCount(data.length);
      }else if(code === -1){
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      }else{
        Toast.show({
          icon: 'fail',
          content: msg,
        });
      }

      setIsShowHelper(true);
    })
    .catch(error => console.error(error));

  }


  async function homeHandler () {
    isExecuted = true;
    const url = `${baseApiLafUrl}get-assistant`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(res => res.json())
    .then(res => {
      const { code, msg, data} = res;
       console.log(res);
      if(code === 1){
        setAssistantLists(data);
        setIsUserAssistantLoading(false);
        setAssistantCount(data.length);
      }else{
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      }

      setIsShowHelper(true);
    })
    .catch(error => console.error(error));
  }

  async function wxHandler(){
    isExecuted = true;
    const url = `${baseApiUrl}api/wxconfig?token=${token}`;
    fetch(url)
    .then(res => res.json())
    .then(res => {
      const { code, msg, data } = res;
      if(code === 1){
        // console.log('=======================>',data);
        wx.config(data);
        wx.ready(function () {
          setWxObj(wx);
          wx.error(function(error) {
            console.log('----------->',error);
          });
        });
      }

      if(code < 0){
        Toast.show({
          icon: 'fail',
          content: msg,
        })
      }

    })
    .catch(error => console.error(error));
  }

  
  // async function onMenuShareToUpdateSign(){

  //   const url = `${baseApiLafUrl}user-sign?utype=share`;
  //   const token = localStorage.getItem('accessToken');
  //   //请求云函数接口创建用户助理
  //   fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`
  //     }
  //   })
  //   .then(res => res.json())
  //   .then(res => {
  //     const { code, msg, data } = res;
  //     if(code === 1){
  //       hanldeOnSignShare(data.num);
  //       // console.log('1111111===========>',userSign);
  //     }else{

  //       if(msg){
  //         Toast.show({    
  //           icon: 'fail',
  //           content: msg,
  //         });
  //       }
  
  //       if(code === -1){
  //         localStorage.clear();
  //         setTimeout(() => {
  //           window.location.href = '/';
  //         }, 1000);
  //       }else{
  //         //hanldeOnSignShare(data.num);
  //       }
  //     }

  //     setRefreshTask(!refreshTask);

  //   })
  //   .catch(error => console.error(error));
  
  // }

  // async function signHandler(){
  //   // console.log('dddddddddddddd');
  //   isExecuted = true;
  //   const url = `${baseApiLafUrl}get-user-sign`;
  //   fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`
  //     }
  //   })
  //   .then(res => res.json())
  //   .then(res => {
  //     const { code, msg, data} = res;
  //      console.log(data);
  //     if(code === 1){
  //       setUserSign(data);

  //     }else{
  //       Toast.show({
  //         icon: 'fail',
  //         content: msg,
  //       });
  //       localStorage.clear();
  //       setTimeout(() => {
  //         window.location.href = '/';
  //       }, 1000);
  //     }

  //     // setIsShowHelper(true);
  //   })
  //   .catch(error => console.error(error));

  // }
  

  useEffect(() => {

    if (!isExecuted) {
      if(isCache){

        homeCacheHandler();
        // if(isLaravel){
        //   homeCacheLaravelHandler();
        // }else{
        //   homeCacheHandler();
        // }
        
      }else{
        homeHandler();
      }
      wxHandler();
      // signHandler();
    }

    //分享给朋友
    wx.onMenuShareAppMessage({
        title: '探索GPT', 
        desc: '互动交流，共享智慧。',
        link: baseUrl+userSignId,
        imgUrl: baseUrl+'ai.png', 
        success: function () {
          // onMenuShareToUpdateSign();
          console.log('====>分享给朋友');
        }
    });

    //分享到朋友圈
    wx.onMenuShareTimeline({
        title: '探索GPT',
        desc: '互动交流，共享智慧。', 
        link: baseUrl+userSignId, 
        imgUrl: baseUrl+'ai.png', 
        success: function () {
          // onMenuShareToUpdateSign();
          console.log('====>分享到朋友圈');
        }
    });

  });

  return (
    <Router initialEntries={['/asst']}>
      <div className="app">
        <div className="body">
          <Switch>

            <Route exact path='/asst' >
              <UserAssistant 
              datas={assistantLists} 
              isLoading={isUserAssistantLoading}
              onMessage={handleUpdateAssistantLists} 
              onBottom={hanldeOnBottom}
              showName={hanldeShowName}
              onChangeFromEdit={handleChangeFromEdit}
              onHelper={hanldeOnHelper}
              />
            </Route>

            <Route exact path='/create-asst'>
              <CreateAssistant 
              assistantCount={assistantCount}
              onMessage={handleIsShowAssistant}
              />
            </Route>

            <Route exact path='/me'>
              <PersonalCenter 
              assistantCount={assistantCount}
              onBottom={hanldeOnBottom}
              onHelper={hanldeOnHelper}
              onPrevious={hanldeOnPrevious}
              // userSign={userSign}
              />
            </Route>

            <Route exact path="/user-gpt">
              <UserGPT 
              onBottom={hanldeOnBottom} 
              onAnswer={handleOnAnswer} 
              onHelper={hanldeOnHelper}
              name={assistantName}
              wxObj={wxObj}
              />
            </Route>

            <Route exact path="/user-collect">
              <UserCollect
                datas={assistantLists} 
                onCollectDetails={hanldeOnCollectDetails}
                onBottom={hanldeOnBottom} 
                onHelper={hanldeOnHelper}
              />
            </Route>

            <Route exact path="/details-collect">
              <DetailsCollect 
                text={collectDetailsText}
              />
            </Route>

            <Route exact path="/helper-asst">
              <HelperAssistant 
              onBottom={hanldeOnBottom} 
              onHelper={hanldeOnHelper}
              previousRoute={previousRoute}
              wxObj={wxObj}
              />
            </Route>

            <Route exact path='/asst-square'>
              <SquareAssistant 
              onHelper={hanldeOnHelper}
              onBottom={hanldeOnBottom} 
              showName={hanldeShowName}
              />
            </Route>

            <Route exact path='/sys-gpt'>
              <SystemGPT 
                onHelper={hanldeOnHelper}
                onBottom={hanldeOnBottom} 
                name={assistantName}
              />
            </Route>

            {/* <Route exact path='/asst-img'>
              <MjDrawImage />
            </Route> */}
            
            {/* <Route exact path='/task-center'>
              <PersonalTask 
              userSign={userSign}
              onBottom={hanldeOnBottom} 
              onHelper={hanldeOnHelper}
              // onSignClock={hanldeOnSignClock}
              // onSignShare={hanldeOnSignShare}
              // onSignHandler={signHandler}
              wxObj={wxObj}
              refresh={refreshTask}
              />
            </Route> */}

             <Route exact path='/user-invitation'>
              <UserInvitation 
              // userSign={userSign}
              onBottom={hanldeOnBottom} 
              onHelper={hanldeOnHelper}
              // onSignClock={hanldeOnSignClock}
              // onSignShare={hanldeOnSignShare}
              // onSignHandler={signHandler}
              wxObj={wxObj}
              // refresh={refreshTask}
              />
            </Route>

            {/* <Route exact path='/renewal'>
              <UserRenewal
              onBottom={hanldeOnBottom} 
              onHelper={hanldeOnHelper}
              previousRoute={previousRoute}
              />
            </Route> */}


          </Switch>
      
        </div>
        {
          isShowBottom && 
          <div className="bottom">
            <Bottom onPrevious={hanldeOnPrevious} />
          </div>
        }

        {
          isShowHelper && 
          <div style={{textAlign: 'center', padding: '50vh 32px 0',}}>
            <Link to="/helper-asst">
              <FloatingBubble
                axis='xy'
                magnetic='x'
                style={{
                  '--initial-position-bottom': '64px',
                  '--initial-position-right': '24px',
                  '--edge-distance': '44px',
                }}
                onClick={showHelper}
              >
                <MessageFill fontSize={32} />
              </FloatingBubble>
            </Link>
          </div>
        }
    
    </div>    
    </Router>
  );

}

export default Home;

