import React,{useState} from 'react'
import { 
  List,
  Empty,
  SwipeAction, 
  Dialog, 
  Toast, 
  Space, 
  DotLoading,
  Button,
  Popup,
  Input,
  Form,
} from 'antd-mobile'

import {
  AppOutline,
} from 'antd-mobile-icons'
import { useHistory } from "react-router-dom";
import './user-assistant.css'

const isCache = JSON.parse(process.env.REACT_APP_IS_CACHE);//缓存开关，打开true,关闭false
const baseApiUrl = process.env.REACT_APP_BASE_API_URL; 
const baseApiLafUrl = process.env.REACT_APP_BASE_API_LAF_URL;
const isLaravel = JSON.parse(process.env.REACT_APP_IS_LARAVEL);
const token = localStorage.getItem('accessToken');

const UserAssistant = (props) => {
  const history = useHistory();
  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [editAid, setEditAid] = useState(null);
  const [editName, setEditName] = useState('');
  const [loading, setLoading] = useState(false);
  
  const [form] = Form.useForm();

  // console.log('props------------------->',props.datas.length);

  const delContentPromise = (id) => new Promise(resolve => {
    // console.log(id);


    if(isCache){
      let url = '';
      if(isLaravel){
        url = `${baseApiUrl}api/wx/del-assistant`;
      }else{
        url = `${baseApiUrl}api/del-assistant`;
      }
      // const url = `${baseApiUrl}api/del-assistant`;

      const postData = {
        id: id,
        token: token
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(postData),  
      })
      .then(res => res.json())
      .then(res => {
        const { code, msg, data} = res;
        if(code < 0){
          Toast.show({
            icon: 'fail',
            content: msg,
          })
        }
  
        if(code === 1){
          Toast.show({
            icon: 'success',
            content: '操作成功',
          })
          props.onMessage(id);
        }
  
        resolve(data);
      })
      .catch(error => console.error(error));  

    }else{
      const url = `${baseApiLafUrl}del-assistant?id=${id}`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(res => {
        const { code, msg, data} = res;
        // console.log(res);
     
        if(code < 0){
          Toast.show({
            icon: 'fail',
            content: msg,
          })
        }
  
        if(code === 1){
          Toast.show({
            icon: 'success',
            content: '操作成功',
          })
          props.onMessage(id);
        }
  
        resolve(data);
      })
      .catch(error => console.error(error));      
    }

  });


  const onFinish = (values) => {
    
    let aid = editAid;
    let assName = values.assName;

    if(assName === editName){
      setVisibleEditForm(false);
    }else{
      setLoading(true);
      
      if(isCache){

        let url = '';
        
        if(isLaravel){
          url = `${baseApiUrl}api/wx/edit-asssistant`;
        }else{
          url = `${baseApiUrl}api/edit-asssistant`;
        }

        const postData = {
          name: assName,
          aid: aid,
          token: token
        };
        fetch(url,{
          method: 'POST',
          body: JSON.stringify(postData),      
        })
        .then(res => res.json())
        .then(res => {
          setLoading(false);
          setVisibleEditForm(false);
          const { code, msg} = res;
          if(code < 0){
            Toast.show({
              icon: 'fail',
              content: msg,
            })
          }
          if(code === 1){
            // console.log('data==>',data);
            Toast.show({
              icon: 'success',
              content: '操作成功',
            })
            props.onChangeFromEdit(aid,assName);
          }
        })
        .catch(error => console.error(error));

      }else{
        const url = `${baseApiLafUrl}edit-asssistant?name=${assName}&aid=${aid}`;
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
        .then(res => res.json())
        .then(res => {
          setLoading(false);
          setVisibleEditForm(false);
          const { code, msg} = res;
          if(code < 0){
            Toast.show({
              icon: 'fail',
              content: msg,
            })
          }
          if(code === 1){
            // console.log('data==>',data);
            Toast.show({
              icon: 'success',
              content: '操作成功',
            })
            props.onChangeFromEdit(aid,assName);
          }
        })
        .catch(error => {
          console.error(error);
        });
      }

    }

  }

  return (
    <>
    
      <Popup visible={visibleEditForm}
        onMaskClick={() => {
          setLoading(false);
          setVisibleEditForm(false);
        }}
        onClose={() => {
          setLoading(false);
          setVisibleEditForm(false);
        }}
        bodyStyle={{ height: '40vh' }}
      >
        <Form 
          form={form}
          // initialValues={assistantName}
          onFinish={onFinish} 
          layout='horizontal'
          footer={
            <Button block loading={loading} loadingText='正在提交' type='submit' color='primary' size='large' >
              提交
            </Button>
          }
        >

          <Form.Header>编辑助理</Form.Header>
          <Form.Item
            name='assName'
            label='名称'
            rules={[{ required: true, message: '名称不能为空' }]}
            shouldUpdate
          >
            <Input placeholder='请输入助理名称' />
          </Form.Item>
        </Form>
      </Popup>
        
      {props.isLoading &&
        <div className="loading-parent">
          <Space direction='horizontal' justify='center' align='center' wrap block style={{ '--gap': '16px' }}>
            <span style={{ fontSize: 24 }}>
              <DotLoading color='primary' />
            </span>
          </Space>
        </div>
      }

      { props.datas.length === 0 && !props.isLoading && 
        <div className="empty-show">
        <Empty
            image={
              <AppOutline
                style={{
                  color: 'var(--adm-color-light)',
                  fontSize: 48,
                }}
              />
            }
            description='暂无数据，请您点击创建助理'
          />
        </div>
      }
  
      
      { props.datas.length > 0 && 
        <div className='user-asst'>
          <List header='我的助理'>
            
              {(props.datas).map(data => (
                <SwipeAction
                    key={data.id}
                    // leftActions={leftActions}
                    rightActions={[{
                      text: '编辑',
                      key: 'edit',
                      color: 'primary',
                      onClick: async () => {
                        setVisibleEditForm(true);
                        // console.log('=====>',{assName: data.name});
                        form.setFieldsValue({assName: data.name});
                        setEditAid(data.id);
                        setEditName(data.name);
                      }
                    },{
                      key: 'delete',
                      text: '删除',
                      color: 'danger',
                      // onClick:  delContent(data.id)
                      onClick: async () => {
                        // console.log('to del');
                        Dialog.confirm({
                          content: '确定要删除吗？',
                          onConfirm: async () => {
                            await delContentPromise(data.id);
                          }
                        })
                      }

                    }]}>
                  <List.Item 
                  description={data.description} 
                  onClick={() => {
                    props.onBottom(false);
                    props.showName(data.name);
                    props.onHelper(false);
                    history.push('/user-gpt',{aid:data.id});
                  }} 
                  >
                    {data.name}
                  </List.Item>
                </SwipeAction>
              ))}
            
          </List>
        </div> 
      }


    
    </>
  )


}

export default UserAssistant;