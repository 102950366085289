import React, {useState, useEffect} from 'react';
import { Grid, List, Button, NavBar, Toast, Image, Empty, Space, DotLoading } from 'antd-mobile'
import {
  UserAddOutline,
//   VideoOutline,
//   FaceRecognitionOutline,
//   ChatCheckOutline,
  UserOutline,
} from 'antd-mobile-icons'
// import {StarOutline,} from 'antd-mobile-icons';
// import { toast } from "@chatui/core";
import { useHistory } from 'react-router-dom';
import './invitation.css'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
const token = localStorage.getItem('accessToken');
let page = 1;
const UserInvitation = (props) => {
    const [isExecuted, setIsExecuted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [invitationList, setInvitationList] = useState([]);
    const [signInvite, setSignInvite] = useState(0);
    const history = useHistory();

    const back = () => {
        props.onBottom(true);
        props.onHelper(true);
        history.push('/me');
    }

    const signInvitOnClick = () => {
        Toast.show({content:"请您点击右上角 ' ... ' 邀请"});
        return;
    }

    async function userInvitationHandler () {
        setIsExecuted(true);
        // console.log('userInvitationHandler======>');
        page = 1;
        const url = baseApiUrl+'api/wx/get-user-invitation';
        const postData = {
            page: page,
            token: token
        };
        fetch(url,{
            method: 'POST',
            body: JSON.stringify(postData),      
        })
        .then(res => res.json())
        .then(res => {
            const { code, msg, data } = res;
            if(code === 1){
                if(data.data.length > 0){
                    setInvitationList(data.data);
                    setSignInvite(data.total);
                }
                setIsLoading(false);

            }else{
                Toast.show({
                    icon: 'fail',
                    content: msg,
                });
            }
        })
        .catch(error => console.error(error));
    }

    useEffect(() => {
        if (!isExecuted) {
            userInvitationHandler(); 
        }
    });


    return (
        <div className="app">
            <div className="top">
                <NavBar onBack={back}>我的邀请</NavBar>
            </div> 
            <div style={{ width: "100%", height: "93%", marginTop: '3.3em' }}>
                <div className='grid-task-center'>
                    <div className='grid-task-center-invitation'>
                        <Grid columns={1} gap={8}>
                            <Grid.Item>
                                <List>
                                    <List.Item 
                                        arrow={false}
                                        extra={
                                            <Button 
                                            color='primary' 
                                            size='small'
                                            disabled={false}
                                            fill={'outline'} 
                                            onClick={() => {
                                                signInvitOnClick();
                                            }}
                                            >
                                            邀请
                                            </Button>
                                        }                 
                                        title={'已成功邀请 '+ signInvite +' 人'}
                                        description='互动交流共享智慧'
                                        prefix={
                                            <UserAddOutline fontSize={48} color='var(--adm-color-primary)'/>
                                        }>
                                        用户邀请
                                    </List.Item>
                                </List>
                            </Grid.Item>
                        </Grid>
                    </div>

                    <div className='grid-task-center-content'>

                        <Grid columns={1} gap={8}>
                            <Grid.Item>
                                <List header='邀请列表'>

                                    { isLoading &&
                                        <div className="loading-parent">
                                            <Space direction='horizontal' justify='center' align='center' wrap block style={{ '--gap': '16px' }}>
                                                <span style={{ fontSize: 24 }}>
                                                <DotLoading color='primary' />
                                                </span>
                                            </Space>
                                        </div>
                                    }

                        
                                    { invitationList.length > 0 &&
                                        <List>
                                        {(invitationList).map(data => (
                                            <List.Item
                                                key={data.id}
                                                prefix={
                                                    <Image
                                                    src={data.invAvatar}
                                                    style={{ borderRadius: 20 }}
                                                    fit='cover'
                                                    width={40}
                                                    height={40}
                                                    />
                                                }
                                                description={data.addTime}
                                            >
                                            {data.invNickname}
                                            </List.Item>
                                        ))}
                                        </List>
                                    }
                                 
                                    { invitationList.length === 0 &&
                                        <div className="empty-show">
                                            <Empty
                                                image={
                                                <UserOutline
                                                    style={{
                                                    color: 'var(--adm-color-light)',
                                                    fontSize: 48,
                                                    }}
                                                />
                                                }
                                                description='暂无邀请记录'
                                            />
                                        </div> 
                                    }


                                </List>
                            </Grid.Item>
                        </Grid>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default UserInvitation;