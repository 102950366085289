import React from 'react';
import { Grid, Avatar, List } from 'antd-mobile';
import {
  // SetOutline,
  StarOutline,
  AppOutline,
  MessageOutline,
  // ClockCircleOutline,
  // ContentOutline,
  UserAddOutline,
} from 'antd-mobile-icons';
// import { toast } from "@chatui/core";
import { useHistory, useLocation } from 'react-router-dom';

import './center.css';
const headimgurl = localStorage.getItem('headimgurl');
const nickname = localStorage.getItem('nickname');
const PersonalCenter = (props) => {
  const location = useLocation();
  const history = useHistory();
  let limitAssistant = `${props.assistantCount}/5`;
  return (
    <div className='grid-center'>
      <div className='grid-center-header'>
        <Grid columns={1} gap={8}>
            <Grid.Item>
              <div className='grid-item-block'>
                <Avatar src={headimgurl} style={{ '--size': '64px' }} />
              </div>
            </Grid.Item>
            <Grid.Item>
            <div className='grid-item-block'><b>{nickname}</b></div>
            </Grid.Item>
        </Grid>
      </div>

      {/* <div className='grid-task-center-header'>
        <Grid columns={4} gap={8}>
          <Grid.Item span={3}>
            <div className="grid-task-item-text-block-time">时效: 2024-11-20</div>
          </Grid.Item>
          <Grid.Item>
            <div className="grid-task-item-text-block-pay">充值</div>
          </Grid.Item>
        </Grid>
      </div> */}

       <div className='grid-task-center-header'>
       {/* <Grid columns={2} gap={8}>
          <Grid.Item>
            <div className="grid-task-item-block"><b>{props.userSign.nos}</b> 次</div>
            <div className="grid-task-item-text-block">对话余额</div>
          </Grid.Item>
          <Grid.Item>
            <div className="grid-task-item-block"><b>5</b> 个</div>
            <div className="grid-task-item-text-block">助理名额</div>
          </Grid.Item>
        </Grid>*/}
      </div> 

      <div className='grid-center-content'>
        <Grid columns={1} gap={8}>

            <Grid.Item>
              <List header=''>

              {/* <List.Item 
                extra={<div><span style={{ marginRight: '2vh' }}>2024-11-20</span><span>时效续期</span></div>} 
                prefix={<ClockCircleOutline fontSize={24} color='var(--adm-color-weak)'/>} 
                onClick={() => {
                  props.onBottom(false);
                  props.onHelper(false);
                  history.push("/renewal");
                }}
                >
                  时效
                </List.Item> */}

                <List.Item 
                  extra={<div><span style={{ marginRight: '2vh' }}>{limitAssistant}</span><span>我的助理</span></div>} 
                  // extra="我的助理"
                  prefix={<AppOutline fontSize={24} color='var(--adm-color-danger)'/>} 
                  onClick={() => {
                    history.push("/asst");
                    props.onPrevious("/asst");
                  }}
                >
                  助理
                </List.Item>

                <List.Item 
                  extra='我的收藏'
                  prefix={<StarOutline fontSize={24} color='var(--adm-color-primary)'/>} 
                  onClick={() => {
                    props.onBottom(false);
                    props.onHelper(false);
                    history.push("/user-collect");
                  }}
                >
                  收藏
                </List.Item>

                {/* <List.Item 
                extra='每日免费'
                prefix={<ContentOutline fontSize={24} color='#76c6b8'/>} 
                onClick={() => {
                  props.onBottom(false);
                  props.onHelper(false);
                  history.push("task-center");
                }}
                >
                任务
                </List.Item> */}

                <List.Item 
                  extra='助手小蜜'
                  prefix={<MessageOutline fontSize={24} color='#ffd700'/>} 
                  onClick={() => {
                    props.onBottom(false);
                    props.onHelper(false);
                    history.push("helper-asst", {from: location.pathname});
                  }}
                >
                助手
                </List.Item>

                <List.Item 
                  extra='好友分享'
                  prefix={<UserAddOutline fontSize={24} color='#76c6b8'/>} 
                  onClick={() => {
                    props.onBottom(false);
                    props.onHelper(false);
                    // history.push("task-center");
                    history.push("/user-invitation");
                  }}
                >
                邀请
                </List.Item>

                {/* <List.Item
                extra='通用设置'
                prefix={<SetOutline fontSize={24} color='var(--adm-color-weak)'/>} 
                onClick={
                  () => {toast.show("设置功能-开发中");
                }}
                >
                设置
                </List.Item> */}
              </List>
            </Grid.Item>
        
        </Grid>
      </div>
    </div>
  );
}

export default PersonalCenter;