import React,{useEffect, useState} from 'react';
import { 
    List,
    Empty,
    SwipeAction, 
    Dialog, 
    Toast, 
    Space, 
    DotLoading,
    NavBar,
  } from 'antd-mobile';
import {StarOutline,} from 'antd-mobile-icons';
import { useHistory } from "react-router-dom";
import './user-collect.css';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
const baseApiLafUrl = process.env.REACT_APP_BASE_API_LAF_URL;
const isCache = JSON.parse(process.env.REACT_APP_IS_CACHE);//缓存开关，打开true,关闭false
const isLaravel = JSON.parse(process.env.REACT_APP_IS_LARAVEL);
const token = localStorage.getItem('accessToken');
let page = 1;
const UserCollect = (props) => {
    const [isExecuted, setIsExecuted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [collectList, setCollectList] = useState([]);
    const history = useHistory();


    const back = () => {
        props.onHelper(true);
        props.onBottom(true);
        history.push('/me');
    }

    async function userCollectHandler () {

        setIsExecuted(true);
        page = 1;

        if(isCache){
            let url = '';
            if(isLaravel){
                url = baseApiUrl+'api/wx/get-user-collect';
            }else{
                url = baseApiUrl+'api/get-user-collect';
            }
            const postData = {
              page: page,
              token: token
            };
            fetch(url,{
                method: 'POST',
                body: JSON.stringify(postData),      
            })
            .then(res => res.json())
            .then(res => {
                const { code, msg, data} = res;
                if(code === 1){
                    // console.log(data);
                    if(data.length > 0){
                        setCollectList(data);
                    }
                    setIsLoading(false);
                }else{
                    Toast.show({
                        icon: 'fail',
                        content: msg,
                    });
                    localStorage.clear();
                    setTimeout(() => {
                    window.location.href = '/';
                    }, 1000);
                }
            })
            .catch(error => console.error(error));


        }else{
            const url = `${baseApiLafUrl}get-user-collect?page=${page}`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => res.json())
            .then(res => {
                const { code, msg, data} = res;
                if(code === 1){
                    // console.log(data);
                    if(data.length > 0){
                        setCollectList(data);
                    }
                    setIsLoading(false);
                }else{
                    Toast.show({
                        icon: 'fail',
                        content: msg,
                    });
                    localStorage.clear();
                    setTimeout(() => {
                    window.location.href = '/';
                    }, 1000);
                }
            })
            .catch(error => console.error(error));
        }

    }

    const cancelCollectPromise = (did,aid) => new Promise(resolve => {
        let url = '';
        if(isCache){
            if(isLaravel){
                url = baseApiUrl+'api/wx/collect-user-dialogue';
            }else{
                url = baseApiUrl+'api/collect-user-dialogue';
            }
            // const url = baseApiUrl+'api/collect-user-dialogue';
            const postData = {
              id: did,
              isCollect: 0,
              aid: aid,
              token: token
            };
            fetch(url,{
                method: 'POST',
                body: JSON.stringify(postData),      
            })
            .then(res => res.json())
            .then(res => {
                const { code, msg, data } = res;
                // console.log(res);
                if(code === 1){
                    Toast.show({content: '已取消'});
                    const filerCollectList = collectList.filter(obj => obj.did !== did);
                    setCollectList(filerCollectList);
                }
                if(code === 0){
                    Toast.show({
                        icon: 'fail',
                        content: msg,
                    });
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1000);
                    return;
                }
                if(code < 0){
                    Toast.show({
                        icon: 'fail',
                        content: msg,
                    });
                    return;
                }
                resolve(data);
            })
            .catch(error => console.error(error));

        }else{
            const url = `${baseApiLafUrl}collect-user-dialogue?id=${did}&isCollect=0&aid=${aid}`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => res.json())
            .then(res => {
                const { code, msg, data } = res;
                // console.log(res);
                if(code === 1){
                    Toast.show({content: '已取消'});
                    const filerCollectList = collectList.filter(obj => obj.did !== did);
                    setCollectList(filerCollectList);
                }
                if(code === 0){
                    Toast.show({
                        icon: 'fail',
                        content: msg,
                    });
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1000);
                    return;
                }
                if(code < 0){
                    Toast.show({
                        icon: 'fail',
                        content: msg,
                    });
                    return;
                }
                resolve(data);
            })
            .catch(error => console.error(error));
        }
    });

    useEffect(() => {
        if (!isExecuted) {
            userCollectHandler(); 
        }
    });

    return(
        <>
            <div className="app">
                <div className="top">
                    <NavBar onBack={back}>我的收藏</NavBar>
                </div>

                <div style={{ width: "100%", height: "100%", marginTop: '3.3em', overflow: 'scroll' }}>
                    { isLoading &&
                        <div className="loading-parent">
                            <Space direction='horizontal' justify='center' align='center' wrap block style={{ '--gap': '16px' }}>
                                <span style={{ fontSize: 24 }}>
                                <DotLoading color='primary' />
                                </span>
                            </Space>
                        </div>
                    }

                    { collectList.length > 0 &&
                        <div className='user-collect'>
                            <List>
                                {(collectList).map(data => (
                                    <SwipeAction
                                        key={data.did}
                                        rightActions={[{
                                            key: 'delete',
                                            text: '取消收藏',
                                            color: 'danger',
                                            onClick: async () => {
                                            Dialog.confirm({
                                                content: '确定要取消收藏吗？',
                                                onConfirm: async () => {
                                                    // console.log(data.did);
                                                    await cancelCollectPromise(data.did,data.aid);
                                                }
                                            })
                                            }

                                        }]}>
                                        <List.Item 
                                        description={data.text} 
                                        onClick={() => {
                                            history.push('/details-collect');
                                            props.onCollectDetails(data.text);
                                        }} 
                                        >
                                        {data.name}
                                        </List.Item>
                                    </SwipeAction>
                                ))}
                            </List>                            
                        </div>
                    }

                    { collectList.length === 0 &&
                        <div className="empty-show">
                        <Empty
                            image={
                            <StarOutline
                                style={{
                                color: 'var(--adm-color-light)',
                                fontSize: 48,
                                }}
                            />
                            }
                            description='暂无收藏'
                        />
                        </div>
                    }

                    </div>
            </div>

        </>
    )

}

export default UserCollect;