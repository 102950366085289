// import { Toast } from 'antd-mobile';
import { Toast } from 'antd-mobile';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';//正式打开

let isExecuted = false;
let redirect_url = process.env.REACT_APP_BASE_URL+'login';
const appid = process.env.REACT_APP_WX_APPID;
const isDev = JSON.parse(process.env.REACT_APP_IS_DEV);
const isLaravel = JSON.parse(process.env.REACT_APP_IS_LARAVEL);
const isInvite = JSON.parse(process.env.REACT_APP_IS_INVITE);

const baseApiLafUrl = process.env.REACT_APP_BASE_API_LAF_URL;
const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const Login = () => {

  let { code, signid } = useParams();

  function loginHandler () {
    isExecuted = true;
    if(isLaravel){

      //正式环境需要做微调--TODO
      if(isDev){
        const url = window.location.href;
        if (url.indexOf('code') !== -1) { 
          let codeDev = url.split('code=')[1].split('&')[0]; 
          let signidDev = '';
          if(url.indexOf('signid') !== -1){
            signidDev = url.split('signid=')[1].split('&')[0];
          }
  
          let wxAuthurl = `${baseApiUrl}api/wx/auth?code=${codeDev}`
          if(signidDev){
            wxAuthurl = wxAuthurl + '&signid=' + signidDev;
          }
          loginWxAuth(wxAuthurl);
        }else{
          loginToWxAuthorize(appid,redirect_url);
        }
      }else{
        if (code) {
          let baseApiUrlForAuth = `${baseApiUrl}api/wx/auth?code=${code}`;
          if(signid){
            baseApiUrlForAuth = baseApiUrlForAuth + '&signid=' + signid;
          }
          loginWxAuth(baseApiUrlForAuth);
        }else{
          loginToWxAuthorize(appid,redirect_url);
        }
      }

    }else{

      if(isDev){
        const url = window.location.href;
        if (url.indexOf('code') !== -1) { 
          let codeDev = url.split('code=')[1].split('&')[0]; 
          let signidDev = '';
          if(url.indexOf('signid') !== -1){
            signidDev = url.split('signid=')[1].split('&')[0];
          }
          let lafurl = `${baseApiLafUrl}wxAuth?code=${codeDev}`
          if(signidDev){
            lafurl = lafurl + '&signid=' + signidDev;
          }
          loginWxAuth(lafurl);
        }else{
          loginToWxAuthorize(appid,redirect_url);
        }
      }else{
        if (code) {
          let baseApiUrlForAuth = `${baseApiUrl}api/auth?code=${code}`;
          if(signid){
            baseApiUrlForAuth = baseApiUrlForAuth + '&signid=' + signid;
          }
          loginWxAuth(baseApiUrlForAuth);
        }else{
          loginToWxAuthorize(appid,redirect_url);
        }
      }

    }

  }

  async function  loginToWxAuthorize(appid,redirect_url){
    redirect_url = encodeURIComponent(redirect_url);
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
  }

  async function loginWxAuth(wxAuthUrl){
    console.log(wxAuthUrl);
    fetch(wxAuthUrl)//正式打开
    .then(res => res.json())
    .then(res => {
      const { code, msg, data } = res;
      console.log(res);
      if(code < 0){
        Toast.show({content: msg});
        return;
      }
      if(code === 1){
        if(isInvite){
          if(data.isinvite === 0){
            window.location.href = '/invitation';
            return;
          }
        }
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("nickname",data.nickname);
        localStorage.setItem("headimgurl",data.headimgurl);
        localStorage.setItem("isLoggedInV5", true);
        localStorage.setItem("userSignId", data.id);
        window.location.href = '/';
      }
    })
    .catch(error => console.error(error));
  }

  useEffect(() => {
    if (!isExecuted) {loginHandler();}
  });
  return (
      <div>
      </div>
  );
}

export default Login;