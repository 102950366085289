import React,{useEffect,useState} from "react";
import { 
  NavBar,
  Toast,
} from 'antd-mobile'

import Chat,{
  Bubble,
  useMessages,
  Notice,
  Icon,
  Card,
  Flex, 
  FlexItem,
  List, 
  ListItem,
  ScrollView,
  CardTitle,
  CardText
} from "@chatui/core";    

// import {
//   StarOutline,
//   DownlandOutline,
// } from 'antd-mobile-icons'

import showdown from 'showdown';
import { useHistory, useLocation } from "react-router-dom";
// import * as XLSX from 'xlsx';


import 'highlight.js/styles/docco.css'
import './helper-asst.css'

const converter = new showdown.Converter(); // eslint-disable-line no-undef
converter.setOption("tables",true);




let initialMessages = [
  {
    type: 'text',
    content: { text: 'Hi，我是您的专属智能助手小蜜，有问题请随时找我哦~' },
    user: { avatar: "/ai.png" },
    createdAt: Date.now(),
    hasTime: true,
  },

];

const skillList = [
  { title: '话费充值', desc: '智能充值智能充值' },
  { title: '评价管理', desc: '我的评价' },
  { title: '联系商家', desc: '急速联系' },
  { title: '红包卡券', desc: '使用优惠' },
  { title: '修改地址', desc: '修改地址' },
];


const defaultQuickReplies = [
  {
    icon: "message",
    name: "小蜜目录",
    isNew: true,
    isHighlight: true,
  },
 
];


const HelperAssistant = (props) => {

  const location = useLocation();
  const history = useHistory();
  const [isExecuted, setIsExecuted] = useState(false);
  const { messages, appendMsg, setTyping, deleteMsg } = useMessages(initialMessages);
  // console.log(location.state);
  // console.log(history);
  const back = () => {
    props.onBottom(true);
    props.onHelper(true);
    if(location.state){
      history.push(location.state.from);
    }else if(props.previousRoute){
      history.push(props.previousRoute);
    }else{
      history.push('/asst');
    }
    
  }

  async function handleSend (type, val) {
    if (type === "text" && val.trim()) {
      appendMsg({
        type: "text",
        content: { text: val.trim() },
        position: "right",
      });
      setTyping(true);
    }
  }


  // function downloadTableToFile(){
  //   let url = `http://172.28.20.36:62082/api/download-table`;

  //   fetch(url)
  //   .then(res => res.json())
  //   .then(res => {
  //     const { code, msg, data } = res;
  //     if(code === 1){
  //       window.location.href = data.fileurl;
  //     }

  //     if(code < 0){
  //       Toast.show({
  //         icon: 'fail',
  //         content: msg,
  //       })
  //     }

  //   })
  //   .catch(error => console.error(error));
  // }

  const askQuestion = (e,n) => {
    // console.log('----->',n);
    let text = e.target.innerHTML;
    let mtext = '';
    const regex = /<div class="ListItem-content">(.*?)<\/div>/; 
    const match = regex.exec(text);
    if (match && match[1]) {
       mtext = match[1];
    }

    if(mtext){
      text = mtext;
    }

    appendMsg({
      type: "html",
      content: { text: converter.makeHtml(text)},
      position: "right",
    });

    setTyping(true);

    setTimeout(() => {
      if(n === 1) {
        let q1 = '<b>1. 找灵感，投喂信息，设定身份，布置任务</b>';
        q1 += '<div><b>找灵感，投喂信息：</b>我的公众号目标用户是职场新晋管理者，目的是通过分享一些案例和管理经验帮助他们掌握管理的知识和技巧，我的风格是用口语化、年轻化的语言讲述一些管理知识，因工作场景案例分享一些管理经验；</div>';
        q1 += '<div><b>设定身份：</b>你是一名有10年撰写公众号文章经验的运营者，你熟悉掌握爆款文章的结构和写法；</div>';
        q1 += '<div><b>布置任务：</b>我需要你出10个下期公众号文章的选题灵感。</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(q1)},
          position: "left",
        });
        let s1 = '<b>小蜜提示:</b>';
        s1 += '<div>把以上内容根据自己的需求发给您的GPT助理</div>';
        s1 += '<div>如果当下有什么热点，您也可以告诉它哦，网址是：https://tophub.today</div>';
        s1 += '<div>不满意您可以根据您的想法跟它说一说，重新产出。</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(s1)},
          position: "left",
        });

        let q2 = '<b>2.选题</b>';
        q2 += '<div><b>接上下文：</b>请你根据《这里放入GPT第1步返回给您的任意一个选题》为灵感，帮我找5个可选的题目。</div>';
        q2 += '<div><b>题目要求：</b>1.突出痛点，能够激发用户共鸣，引发评论；2.结合时下热点；3.通过利益引导</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(q2)},
          position: "left",
        });

        let q3 = '<b>3.框架</b>';
        q3 += '<div><b>接上下文：</b>请你根据《这里放入ChatGPT第2步返回给您的任意一个选题》这个题目，出3个大纲，要求结构为金字塔原理。</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(q3)},
          position: "left",
        });

        
        let q4 = '<b>4.写作</b>';
        q4 += '<div><b>接上下文：</b>请你将这部分内容展开写一篇500字的文章。要求: 包括认知心理学、脑神经科学的一些理论，管理上的一些案例。</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(q4)},
          position: "left",
        });

        let s4 = '<b>小蜜提示:</b>';
        s4 += '<div>把第3部框架分段让GPT展开创作</div>';
        s4 += '<div>要求可以根据自己的需求填写哦，在GPT回复中留下给您好灵感的，其他全部删除，再用您自己的语言体系把它们梳理通顺</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(s4)},
          position: "left",
        });
                
        let q5 = '<b>5.完善</b>';
        q5 += '<div>你觉得下面这段文字有什么可优化的地方？</div>';
        q5 += '<div>这里粘贴复制修整的文章。</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(q5)},
          position: "left",
        });


        let s5 = '<b>小蜜提示:</b>';
        s5 += '<div>把您修整的文章重新发给GPT，问它，你觉得这个文字有什么优化的地方，让它帮您润色发布。</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(s5)},
          position: "left",
        });


        let s6 = '<b>小蜜总结:</b>';
        s6 += '<div>AI做的不是成品而是模块，而人类的创意体现在我可以提前把模块单独生成出来，我知道怎么合理的组装，以及最后该怎么去润色，GPT只是帮助我们打开更多的思路，您可以从中挑选出您想要的，再让它往下走，实现真正的人机交互。</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(s6)},
          position: "left",
        });
      } else if(n === 2) {
        let q1 = '<b>角色+目标+背景描述+要求</b>';
        q1 += '<div><b>角色：</b>你是我的邮件助手,</div>';
        q1 += '<div><b>目标：</b>现在需要你根据我的描述写一篇请求类邮件。</div>';
        q1 += '<div><b>背景描述：</b>我是一名对接A公司的广告策划，现在需要给A公司B部门的C主管写一份邮件，请求发送D广告项目的相关文件。</div>';
        q1 += '<div><b>要求：</b>要求语言简介明了，使用合适的礼貌用语和敬语，尊重收件人的身份和地位，注意以邮件的格式和排版，使用适当的字体、字号和间距。要清楚说明请示的具体事项和期望获得的意见或者批准；提供相关背景信息以助对方理解并且说明相应期限和重要性；表达感谢并询问是否有其他信息或材料提供，提供联系方式。</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(q1)},
          position: "left",
        });

        let s1 = '<b>小蜜提示:</b>';
        s1 += '<div>通过特殊要求的设定，GPT的答案更加全面，生成结果质量取决于您的指令，而您的指令质量，取决于您对工作的理解程度。</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(s1)},
          position: "left",
        });
      } else if(n === 3) {

        let q1 = "根据以上内容，以下是我为您推荐的5首合适的背景音乐：\n\n| 序号 | 标题                           | 艺术家              |\n|------|--------------------------------|---------------------|\n| 1    | \"Morning Mist\"                | Kevin MacLeod       |\n| 2    | \"Gentle Awakening\"            | Adrian von Ziegler |\n| 3    | \"Peaceful Dawn\"               | Eric Matyas        |\n| 4    | \"Serene Moments\"              | Scott Holmes        |\n| 5    | \"Sunrise Reflections\"         | Puddle of Infinity |\n\n这些音乐都具有柔和、平静的氛围，能够与宣传片的场景相衬托，为观众呈现出梦幻般的早晨氛围。请根据您的喜好选择其中一首作为宣传片的背景音乐。";
        
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(q1)},
          // content: {text: q1},
          position: "left",
        });

        let s1 = '<b>小蜜提示:</b>';
        s1 += '<div><b>举例：</b>某文案或者项目最后需要挑选适合的背景音乐，您可以通过指定消息的提问生成表格哦。</div>';
        s1 += '<div><b>提问：</b>请你根据以上内容，通过表格的形式推荐5首合适的背景音乐，供我挑选。</div>';
        s1 += '<div><b>注意：</b>提问语句中要有[表格]两个字。</div>';
        appendMsg({
          type: "html",
          content: { text: converter.makeHtml(s1)},
          position: "left",
        });
      } else if(n === 7){
        //Toast.show({content: 'markdown to excel'});
        // Toast.show({content: '整理中...'});
        // let s1 = 'test-downlaod';
        // appendMsg({
        //   type: "html",
        //   content: { text: converter.makeHtml(s1)},
        //   position: "left",
        // });
        // let url = `http://172.28.20.36:62082/api/download-table`;

        // fetch(url)
        // .then(res => res.json())
        // .then(res => {
        //   const { code, msg, data } = res;
        //   if(code === 1){
        //     window.location.href = data.fileurl;
        //   }
    
        //   if(code < 0){
        //     Toast.show({
        //       icon: 'fail',
        //       content: msg,
        //     })
        //   }
    
        // })
        // .catch(error => console.error(error));
        Toast.show({content: '整理中...'});
      } else {
        Toast.show({content: '整理中...'});
      }
      setTyping(false);
    }, 1000);

  }



  function handleQuickReplyClick (item) {
    setTyping(true);
    setTimeout(() => {
      appendMsg({
        type: "guess-you"
      });
    }, 1000);
  }

  function renderMessageContent({ type, content, _id, user }) {
    // 根据消息类型来渲染
    switch (type) {
      case "text":
        return <Bubble content={content.text} />;
      case "error":
        return (
          <Bubble content={content.text}>
            <Icon
              onClick={reSend}
              type="refresh"
              className="btn-refresh"
            />
          </Bubble>
        );
      case "html":
        return (
          <Bubble>
            <div dangerouslySetInnerHTML={{ __html: content.text }}></div>
            {/* <div style={{ fontSize: 24,textAlign: 'right' }}>
              <DownlandOutline 
                  color='var(--adm-color-primary)' 
                  style={{ fontSize: 24,paddingRight: '4vh' }}
                  onClick={()=>{
                    downloadTableToFile();
                  }}
              />
              <StarOutline color='var(--adm-color-primary)' onClick={()=>{
               
              }}/>
            </div> */}
          </Bubble>
        );
      case "notice":
        return (
          <Notice
            content={content.text}
            onClose={deleteMsg.bind(this, _id)}
          />
        );
        case 'guess-you':
          return (
            <Card fluid>
              <Flex>
                <FlexItem>
                  <List>
                    <ListItem content="吸引眼球的公众号文章。" as="a" rightIcon="chevron-right" onClick={(e) => {askQuestion(e,1);}}/>
                    <ListItem content="搞定工作邮件。" as="a" rightIcon="chevron-right" onClick={(e) => {askQuestion(e,2)}}/>
                    <ListItem content="生成文档表格。" as="a" rightIcon="chevron-right" onClick={(e) => {askQuestion(e,3);}}/>
                    <ListItem content="文案写法训练[小样本提示]。" as="a" rightIcon="chevron-right" onClick={(e) => {askQuestion(e,4);}}/>
                    <ListItem content="团队OKR顾问。" as="a" rightIcon="chevron-right" onClick={(e) => {askQuestion(e,5);}}/>
                    <ListItem content="批量生成爆款标题。" as="a" rightIcon="chevron-right" onClick={(e) => {askQuestion(e,6);}}/>
                    <ListItem content="生成xlsx。" as="a" rightIcon="chevron-right" onClick={(e) => {askQuestion(e,7);}}/>
                  </List>
                </FlexItem>
              </Flex>
            </Card>
          );     
        case 'skill-cards':
          return (
            <ScrollView
              className="skill-cards"
              data={skillList}
              fullWidth
              renderItem={(item) => (
                <Card>
                  <CardTitle>{item.title}</CardTitle>
                  <CardText>{item.desc}</CardText>
                </Card>
              )}
            />
          );   
      default:
        return null;
    }
  }

  function reSend () {
    for (const m of messages) {
      if (m.position === "right") {
        handleSend("text", m.content?.text);
        break
      }
    }
  }

  async function helpAsstHandler () {
    setIsExecuted(true);

    appendMsg({
      type: "guess-you",
      position: "left",
    });

    // appendMsg({
    //   type: "skill-cards",
    //   position: "letf",
    // });
  }


  useEffect(() => {
    if (!isExecuted) {
      helpAsstHandler();
    } 
    document.querySelectorAll('pre code').forEach((el) => hljs.highlightElement(el)); // eslint-disable-line no-undef
  });

  //空给Composer输入
  const EmptyComponent = () => {
    return <div></div>;
  };

  return (
    <div className="app">
      <div className="top">
          <NavBar onBack={back}>助手小蜜</NavBar>
      </div> 
   
      <div style={{ width: "100%", height: "93%", marginTop: '3.3em' }}>
     
      <Chat
        messages={messages}
        renderMessageContent={renderMessageContent}
        quickReplies={defaultQuickReplies}
        onQuickReplyClick={handleQuickReplyClick}
        onSend={handleSend}
        Composer={EmptyComponent}
      />
    
      </div>
    </div>
  );
}

export default HelperAssistant;