import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';//正式打开

let isExecuted = false;
let redirect_url = process.env.REACT_APP_BASE_URL+'login';
let appid = process.env.REACT_APP_WX_APPID;

const Sign = () => {

  let { signid } = useParams();

  function signHandler () {
    isExecuted = true;
    if (signid) { 
      // console.log("ppppppp======================>>>>",signid);
      redirect_url = redirect_url + '?signid='+signid
      redirect_url = encodeURIComponent(redirect_url);
      console.log(redirect_url);
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
    }else{
      window.location.href = '/';
    }
  }
  useEffect(() => {
   if (!isExecuted) {signHandler();}
  });
  return (
      <div>
      </div>
  );
}

export default Sign;