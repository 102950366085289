import React, { useEffect,useState } from 'react';
import {
  Form,
  Input,
  Button,
  Toast, 
  Grid,
  // NavBar,
  List,
  // Image,
} from 'antd-mobile'

import './invitation.css'
// import { useParams } from 'react-router-dom';//正式打开

let isExecuted = false;
const baseUrl = process.env.REACT_APP_BASE_URL;
// const invitationImageSrc = baseUrl+'invitation-master3.jpg';
const isCache = JSON.parse(process.env.REACT_APP_IS_CACHE);//缓存开关，打开true,关闭false
const baseApiUrl = process.env.REACT_APP_BASE_API_URL; 

const Invitation = () => {
  const [loading, setLoading] = useState(false);


  const onFinish = (values) => {

    setLoading(true);

    if(isCache){

      const url = `${baseApiUrl}api/user-invite`;
      const inviteCode = values.code;
      const postData = {
        code: inviteCode,
      };

      fetch(url,{
        method: 'POST',
        body: JSON.stringify(postData),      
      })
      .then(res => res.json())
      .then(res => {
        setLoading(false);
        const { code, msg } = res;
        if(code === 1){
          // console.log(baseApiUrl+inviteCode);
          Toast.show({
            icon: 'success',
            content: '验证成功。',
          });
          setTimeout(() => {
            window.location.href = baseUrl+inviteCode;
          }, 1000);
          return;
        }

        if(code < 1){
          Toast.show({
            content: msg
          });
        }
  
      })
      .catch(error => console.error(error));

    }else{
     
    }
   

  }


  function invitationHandler () {
    isExecuted = true;
  }

  useEffect(() => {
   if (!isExecuted) {invitationHandler();}
  });


  const checkInvite = (ma, value) => {
    const regex = /^\d{8}$/;
    if (regex.test(value)) {
      return Promise.resolve()
    }else{
      return Promise.reject(new Error('邀请码必须为8位数字'))
    }
  }


  return (
    <div className="app">
    <div style={{ width: "100%", height: "100%", marginTop: '1.4em' }}>
    <div className='grid-task-center'>
    <div className='grid-task-center-content'>
      <Grid columns={1} gap={8}>

          <Grid.Item>
            <List>
                <Form
                  onFinish={onFinish}
                  layout='horizontal'
                  footer={
                    <Button block loading={loading} loadingText='正在验证' type='submit' color='primary' size='large' >
                      验证
                    </Button>
                  }
                >
                  <Form.Item
                    name='code'
                    label='邀请码'
                    rules={[
                      { required: true, message: '邀请码不能为空' },
                      { validator: checkInvite }
                    ]}
                  >
                    <Input type='number' placeholder='请您输入邀请码' />
                  </Form.Item>
                </Form>
            </List>
          </Grid.Item>
        
      </Grid>

      {/* <Grid columns={1} gap={8}>
            <Grid.Item></Grid.Item>
            <Grid.Item>
              <List  header='如果您没有邀请码，请您扫描以下二维码申请。'>
              <Image 
                src={invitationImageSrc} 
              />
              </List>
        </Grid.Item>
      </Grid> */}
    </div>


  </div>

  
    </div>
  </div>
  );
}

export default Invitation;