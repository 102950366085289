import React from 'react';
import {
  // Toast, 
  List,
  Image,
  // Tabs,
  Tag,
} from 'antd-mobile';
import {MessageOutline,} from 'antd-mobile-icons';
import { useHistory } from "react-router-dom";
import './square-asst.css';
const SquareAssistant = (props) => {
  const history = useHistory();


  return (
    <div>



    <div className='square-asst'>
        <List>
            <List.Item key='x' 
              prefix={<Image src='./family-doctor.png'/>} 
              description={
                <div>
                  <div className='square-tag'><MessageOutline color='var(--adm-color-primary)' /> 4.2万</div>
                  <div className='square-tag tag-left tag-seg'><Tag color='#87d068'>|</Tag></div>
                  <div className='square-tag tag-left'><Tag color='#87d068' fill='outline'>文案</Tag></div>
                  {/* <div className='square-tag tag-left'><Tag color='primary' fill='outline'>知识</Tag></div> */}
                  <div className='square-tag tag-left'><Tag color='primary' fill='outline'>话术</Tag></div>
                  {/* <div>我是您的直播文案助手，为您提供直播话术模板：上播话术，产品介绍，价值话术，赋能话术，促单话术，逼单话术，下播话术。</div> */}
                  <div>我是文易，你的直播文案助手！</div>
                  <div>告诉我你的主播昵称、产品相关信息，让我来给你的直播文案提供灵感吧！</div>
                </div>
              } 
              onClick={
                () => {
                  // Toast.show({content: '开发中...'})
                  props.onHelper(false);
                  props.onBottom(false);
                  // props.showName('直播文案助手');
                  props.showName('文易');
                  history.push('/sys-gpt',{aid:'abcdefghijklmn'});
                }
              }
              >
                文易
              {/* 直播文案助手 */}
            </List.Item>

              {/* <List.Item key='a' 
              prefix={<Image src='./family-doctor.png'/>} 
              description={
                <div>
                  <div className='square-tag'><MessageOutline color='var(--adm-color-primary)' /> 4.2万</div>
                  <div className='square-tag tag-left tag-seg'><Tag color='#87d068'>|</Tag></div>
                  <div className='square-tag tag-left'><Tag color='#87d068' fill='outline'>生活</Tag></div>
                  <div className='square-tag tag-left'><Tag color='primary' fill='outline'>知识</Tag></div>
                  <div>我是您的专属家庭医生。</div>
                </div>
              } 
              onClick={
                () => {
                  Toast.show({content: '开发中...'})
                }
              }
              >
              家庭医生
              </List.Item>

              <List.Item key='b' 
              prefix={<Image src='./parenting.png'/>} 
              description={
                <div>
                  <div className='square-tag'><MessageOutline color='var(--adm-color-primary)' /> 52.2万</div>
                  <div className='square-tag tag-left tag-seg'><Tag color='#87d068'>|</Tag></div>
                  <div className='square-tag tag-left'><Tag color='#87d068' fill='outline'>生活</Tag></div>
                  <div className='square-tag tag-left'><Tag color='primary' fill='outline'>知识</Tag></div>
                  <div>一个专门为父母提供育儿建议和指导的助手。</div>
                </div>
              }  
              onClick={
                () => {
                  Toast.show({content: '开发中...'})
                }
              }
              >
              育儿助手
              </List.Item>

              <List.Item key='c' 
              prefix={<Image src='./trainer.png' />} 
              description={
                <div>
                  <div className='square-tag'><MessageOutline color='var(--adm-color-primary)' /> 1.9万</div>
                  <div className='square-tag tag-left tag-seg'><Tag color='#87d068'>|</Tag></div>
                  <div className='square-tag tag-left'><Tag color='#87d068' fill='outline'>生活</Tag></div>
                  <div className='square-tag tag-left'><Tag color='primary' fill='outline'>知识</Tag></div>
                  <div>我是健身教练,根据您的情况帮您定制健身计划。</div>
                </div>
              }   
              onClick={
                () => {
                  Toast.show({content: '开发中...'})
                }
              }
              >
              健身教练
              </List.Item> */}
        </List>

      {/* <Tabs defaultActiveKey='0'>

          <Tabs.Tab title='推荐' key='0'>
            <List>
              
                <List.Item key='a' 
                prefix={<Image src='./family-doctor.png'/>} 
                description={
                  <div>
                    <div className='square-tag'><MessageOutline color='var(--adm-color-primary)' /> 4.2万</div>
                    <div className='square-tag tag-left tag-seg'><Tag color='#87d068'>|</Tag></div>
                    <div className='square-tag tag-left'><Tag color='#87d068' fill='outline'>生活</Tag></div>
                    <div className='square-tag tag-left'><Tag color='primary' fill='outline'>知识</Tag></div>
                    <div>我是您的专属家庭医生。</div>
                  </div>
                } 
                onClick={
                  () => {
                    Toast.show({content: '开发中...'})
                  }
                }
                >
                家庭医生
                </List.Item>

                <List.Item key='b' 
                prefix={<Image src='./parenting.png'/>} 
                description={
                  <div>
                    <div className='square-tag'><MessageOutline color='var(--adm-color-primary)' /> 52.2万</div>
                    <div className='square-tag tag-left tag-seg'><Tag color='#87d068'>|</Tag></div>
                    <div className='square-tag tag-left'><Tag color='#87d068' fill='outline'>生活</Tag></div>
                    <div className='square-tag tag-left'><Tag color='primary' fill='outline'>知识</Tag></div>
                    <div>一个专门为父母提供育儿建议和指导的助手。</div>
                  </div>
                }  
                onClick={
                  () => {
                    Toast.show({content: '开发中...'})
                  }
                }
                >
                育儿助手
                </List.Item>

                <List.Item key='c' 
                prefix={<Image src='./trainer.png' />} 
                description={
                  <div>
                    <div className='square-tag'><MessageOutline color='var(--adm-color-primary)' /> 1.9万</div>
                    <div className='square-tag tag-left tag-seg'><Tag color='#87d068'>|</Tag></div>
                    <div className='square-tag tag-left'><Tag color='#87d068' fill='outline'>生活</Tag></div>
                    <div className='square-tag tag-left'><Tag color='primary' fill='outline'>知识</Tag></div>
                    <div>我是健身教练,根据您的情况帮您定制健身计划。</div>
                  </div>
                }   
                onClick={
                  () => {
                    Toast.show({content: '开发中...'})
                  }
                }
                >
                健身教练
                </List.Item>
               
                
            </List>
       
          </Tabs.Tab>
    
          <Tabs.Tab title='生活百科' key='1'>
            开发中...
          </Tabs.Tab>
          <Tabs.Tab title='知识图谱' key='2'>
            开发中...
          </Tabs.Tab>
          <Tabs.Tab title='文案策划' key='3'>
            开发中...
          </Tabs.Tab>
          <Tabs.Tab title='实用工具' key='4'>
            开发中...
          </Tabs.Tab>
          <Tabs.Tab title='语言学习' key='5'>
            开发中...
          </Tabs.Tab>
          <Tabs.Tab title='名人明星' key='6'>
            开发中...
          </Tabs.Tab>
          <Tabs.Tab title='影视小说' key='7'>
            开发中...
          </Tabs.Tab>
          <Tabs.Tab title='情感倾诉' key='8'>
            开发中...
          </Tabs.Tab>
        </Tabs> */}
    </div>

    </div>
  );
}

export default SquareAssistant;

