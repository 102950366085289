import React,{useEffect,useState} from "react";
import { 
  Button,
  NavBar,
  Input,
  Form,
  Toast,
  Collapse,
  DotLoading,
} from 'antd-mobile'
import Chat,{
  Bubble,
  useMessages,
  Notice,
  Icon,

} from "@chatui/core";   
// import { useHistory,useLocation } from "react-router-dom";

import {ArrowDownCircleOutline,} from 'antd-mobile-icons';
import { useHistory } from "react-router-dom";

import 'highlight.js/styles/docco.css'
import './sys-gpt.css';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
const wssUrl = process.env.REACT_APP_WWS_URL;
const sensitiveWords = JSON.parse(process.env.REACT_APP_IS_SENSITIVE);//过滤词开关，打开true,关闭false

let wss = null;
let lockReconnect = false;  //避免ws重复连接

const converter = new showdown.Converter();  // eslint-disable-line no-undef
converter.setOption("tables",true);

let initialMessages = [];
const token = localStorage.getItem('accessToken');
let replyCheck = {};
let heartCheck = {};
let isReconnect = false;

let firstReply = true;
let did = '';
let replayStr = '';
let replayStrIndex = 0;
let replayStrProcess = '';

const SystemGPT = (props) => {
  const [isExecuted, setIsExecuted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accordioning, setAccordioning] = useState(false);
  const history = useHistory();
  const { messages, appendMsg, updateMsg, setTyping, deleteMsg } = useMessages(initialMessages);

  if(!isExecuted){
    heartCheck = {
      timeout: 30*1000,        //心跳检测
      timeoutObj: null,
      serverTimeoutObj: null,
      reset: function(){
          clearTimeout(this.timeoutObj);
          clearTimeout(this.serverTimeoutObj);
          return this;
      },
      start: function(){
          var self = this;
          this.timeoutObj = setTimeout(function(){
              sendMessageSocket('ping',{});
              console.log("=========>>>ping!")
              self.serverTimeoutObj = setTimeout(function(){//如果超过一定时间还没重置，说明后端主动断开了
                  wss.close();     //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
              }, 8000)
          }, this.timeout)
      }
    };  
    
    replyCheck = {
      timeout: 70, //字符出输出速度
      complete: 1000,//回复完毕后检查是否输出完毕执行时间
      timeoutObj: null,
      completeObj:null,
      reset: function(){
        firstReply = true;
        did = '';
        replayStr = '';
        replayStrIndex = 0;
        replayStrProcess = '';
        clearInterval(this.timeoutObj);
        clearInterval(this.completeObj);
        return this;
      },
      startComplete: function(dataGpt){
        var self = this;
        this.completeObj = setInterval(() => {
          if(replayStrProcess === replayStr){
            clearInterval(self.timeoutObj);
            clearInterval(self.completeObj);
            if(sensitiveWords){//过滤词开关
              const url = `${baseApiUrl}api/sensitive_words`;
              const postData = {
                did: did,
                text: replayStr,
                token: token,
              }
              fetch(url, {
                method: 'POST',
                body: JSON.stringify(postData),
              })
              .then(res => res.json())
              .then(res => {
                const { code, msg, data} = res;
                if(code === 1){
                  updateMsg(did,{
                    _id: did,
                    type: "html",
                    content: { text: converter.makeHtml(data.text) },
                    user: { avatar: "/ai.png" },
                  });
                  replayStr = '';
                  did = '';
                  replayStrIndex = 0;
                  replayStrProcess = '';
                  firstReply = true;
                  dataGpt.text = data.text;
                  saveAnswer(dataGpt);
                }

                if(code < 0){
                  console.log( '请求过滤接口异常:50003',msg );
                  updateMsg(did,{
                    _id: did,
                    type: "html",
                    content: { text: converter.makeHtml(replayStr) },
                    user: { avatar: "/ai.png" },
                  });
                  replayStr = '';
                  did = '';
                  replayStrIndex = 0;
                  replayStrProcess = '';
                  firstReply = true;
                  saveAnswer(dataGpt);
                }
              }).catch(error => {
                console.error('请求过滤接口异常:50001',error);
                updateMsg(did,{
                  _id: did,
                  type: "html",
                  content: { text: converter.makeHtml(replayStr) },
                  user: { avatar: "/ai.png" },
                });
                replayStr = '';
                did = '';
                replayStrIndex = 0;
                replayStrProcess = '';
                firstReply = true;
                saveAnswer(dataGpt);
              });
            }else{
              saveAnswer(dataGpt);
              updateMsg(did,{
                _id: did,
                type: "html",
                content: { text: converter.makeHtml(replayStr), isReplyEnd: 1 },
                user: { avatar: "/ai.png" },
              });
              replayStr = '';
              did = '';
              replayStrIndex = 0;
              replayStrProcess = '';
              firstReply = true;
            }
          }
        }, this.complete);
      },
      startProcess: function(){
        this.timeoutObj = setInterval(() => {
           if(replayStr !== ''){
              if(replayStr[replayStrIndex]){
                replayStrProcess = replayStrProcess + replayStr[replayStrIndex];
                replayStrIndex++;
                if(firstReply){
                  firstReply = false;
                  appendMsg({
                    _id: did,
                    type: "html",
                    content: { text: converter.makeHtml(replayStrProcess), isReplyEnd: 2 },
                    user: { avatar: "/ai.png" },
                  });
                }else{
                  updateMsg(did,{
                    _id: did,
                    type: "html",
                    content: { text: converter.makeHtml(replayStrProcess), isReplyEnd: 2 },
                    user: { avatar: "/ai.png" },
                  });  
                }
              }
           }
        }, this.timeout);    
      }
    };
  }

  function saveAnswer(dataGpt) {
    const  sysAnswerUrl = `${baseApiUrl}api/wx/save-sys-answer`;
    const sysAnswerData = {
      id: dataGpt.id,
      aid: dataGpt.aid,
      text: dataGpt.text,
      token: token
    }
    //请求云函数接口创建用户助理
    fetch(sysAnswerUrl, {
      method: 'POST',
      body: JSON.stringify(sysAnswerData),
    }).catch(error => console.error(error)); 
  }

  const back = () => {

    if(replyCheck.timeout){
      replyCheck.reset();
    }

    if(heartCheck.timeout){
      heartCheck.reset();
    }
    isReconnect = false;
    if(wss){
      wss.close();
    }
    props.onBottom(true);
    props.onHelper(true);
    history.push('/asst-square');
  }

  function reSend () {
    for (const m of messages) {
      if (m.position === "right") {
        // handleSend("text", m.content?.text);
        break
      }
    }
  }

  function renderMessageContent({ type, content, _id }) {
    // 根据消息类型来渲染
    switch (type) {
      case "text":
        return <Bubble content={content.text} />;
      case "error":
        return (
          <Bubble content={content.text}>
            <Icon
              onClick={reSend}
              type="refresh"
              className="btn-refresh"
            />
          </Bubble>
        );
      case "html":
        return (
          <Bubble>
          <div>
            <div dangerouslySetInnerHTML={{ __html: content.text }} ></div>
            <div style={{ fontSize: 24,textAlign: 'right' }}>
            { content.isReplyEnd === 2 &&
              <span style={{ fontSize: 14 }}>
                  <DotLoading color='primary' />
              </span>   
            }
            </div>
          </div>
          </Bubble>
          
        );
      case "notice":
        return (
          <Notice
            content={content.text}
            onClose={deleteMsg.bind(this, _id)}
          />
        );
      default:
        return null;
    }
  }

  const onFinish = (values) => {
    setLoading(true);
    setAccordioning(false);
    setTimeout(() => {
      let url = baseApiUrl+'api/wx/ask-user-sys-dialogue';
      const postData = {
        aid: 1,
        ask: values,
        token: token
      };
      console.log('====>',postData);
      setTyping(true);
      fetch(url,{
        method: 'POST',
        body: JSON.stringify(postData),      
      })
      .then(res => res.json())
      .then(res => {
        // console.log('==>',res);
        const { code, msg, data} = res;
        if(code === 1){

          console.log("askv3-param====>",{ accessToken: token, askId: data.askId, aid: data.aid, parentMessages: data.parentMessages });
          sendMessageSocket('askv3',{accessToken: token, askId: data.askId, aid: data.aid, parentMessages: data.parentMessages});
          replyCheck.reset().startProcess();

        }else if(code === 501){
          Toast.show({
            icon: 'fail',
            content: '登录过期',
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.href = '/';
          }, 1000);
          return;
        }else{
          Toast.show({icon: 'fail',content: msg,});
        }
        setLoading(false);
        setAccordioning(true);
      })
      .catch(error => console.error(error));
    }, 1000);
    
  }

  //空给Composer输入
  const EmptyComponent = () => {

    return (
      <div  className="sysgpt">
        <Collapse
          accordion={accordioning}
          defaultActiveKey={['1']}
        >
          <Collapse.Panel
            title='请您完善信息'
            key='1'
            arrow={<ArrowDownCircleOutline />}
          >
            <div>
              <Form
              onFinish={onFinish}
              layout='horizontal'
              footer={
                <Button block loading={loading} loadingText='正在提交' type='submit' color='primary' size='large' >
                  提交
                </Button>
              }
              >
              <Form.Item
                name='nickname'
                label='主播昵称'
                rules={[{ required: true, message: '昵称不能为空' }]}
              >
                <Input  placeholder='请输入主播昵称' />
              </Form.Item>
              <Form.Item
                name='product'
                label='产品名称'
                rules={[{ required: true, message: '名称不能为空' }]}
              >
                <Input  placeholder='请输入产品名称' />
              </Form.Item>
              <Form.Item
                name='intro'
                label='产品介绍'
              >
                <Input  placeholder='请输入产品介绍' />
              </Form.Item>
            </Form>
            </div>
        </Collapse.Panel>
      </Collapse>
    </div>
    );


  };

    async function systemGptHandler () {
      setIsExecuted(true);


      appendMsg({
        _id: 'abc1',
        type: "html",
        content: { text: converter.makeHtml('我是文易，你的直播文案助手！') },
        user: { avatar: "/ai.png" },
      });

      appendMsg({
          _id: 'abc2',
          type: "html",
          content: { text: converter.makeHtml('告诉我你的主播昵称、产品相关信息，让我来给你的直播文案提供灵感吧！') },
          user: { avatar: "/ai.png" },
      });

    }

    const sendMessageSocket = (requestType,requestData) => {
      const message = JSON.stringify({ type: requestType, data: requestData });
      console.log('wss-state=>',wss.readyState);
      if(wss.readyState === WebSocket.OPEN){
        console.log('readyState=========================>',requestType,requestData);
        wss.send(message);
      }else{
        setTyping(false);
        console.log('========================>connect not start');
        wss.close();
      }
      
    };


    async function initEventHandle(){

      wss.onopen = (socket) => {
        console.log("connected");
        sendMessageSocket('connect',{ "accessToken": token });
      };
      
      wss.onmessage = (event) => {//如果获取到消息，心跳检测重置
        heartCheck.reset().start();//拿到任何消息都说明当前连接是正常的
        const dataJson = JSON.parse(event.data);
        console.log("收到了新的信息......",dataJson.actionType);
        if(dataJson.actionType === 'error'){
          console.log('actionType================>error');
          Toast.show({content:dataJson.msg});
          setTyping(false);
        }
  
        if(dataJson.actionType === 'connectInit'){
          console.log('actionType================>connectInit');
        }


        if(dataJson.actionType === 'replyProcess'){
          replayStr = dataJson.data.text;
          did = dataJson.data.id;
        }

        if(dataJson.actionType === 'replyEnd'){
          console.log('actionType================>replyEnd');
          replayStr = dataJson.data.text;
          replyCheck.startComplete(dataJson.data);
        }
  
        if(dataJson.actionType === 'pong'){
          console.log('actionType================>pong');
        }
  
      };
  
      wss.onclose = () => {
        console.log("closed=========>",isReconnect);
        if(isReconnect){
          reconnect(wssUrl,'onclose');
        }
      };
  
      wss.onerror = function () {
        console.log("bbbbbbbbbbbbbbbbb==========>wss连接错误!");
        setTyping(false);
        Toast.show({content:"网络异常,请返回助理列表,重新进入聊天界面"});
      };
    }

    async function createWebSocket(url){
      console.log('to create websocket');
      try{
        if('WebSocket' in window){
            wss = new WebSocket(url);
        }
        initEventHandle();
      }catch(e){
          console.log(e);
          Toast.show({content:"网络异常请稍后再试!"});
      }
    }

    //重新连接
    function reconnect(url,str) {
      console.log('========================>reconnect!!!!====>',str);
      if(lockReconnect) return;
      lockReconnect = true;
      setTimeout(function () {     //没连接上会一直重连，设置延迟避免请求过多
          createWebSocket(url);
          lockReconnect = false;
      }, 3000);
    }

    useEffect(() => {
      if (!isExecuted) {
        systemGptHandler();
        createWebSocket(wssUrl);
      }
      document.querySelectorAll('pre code').forEach((el) => hljs.highlightElement(el)) // eslint-disable-line no-undef
    });

  return (
    <div className="app">
      <div className="top">
          <NavBar onBack={back}>{props.name}</NavBar>
      </div> 

      <div style={{ width: "100%", height: "94%", marginTop: '3.3em' }}>
        <Chat
          messages={messages}
          renderMessageContent={renderMessageContent}
          Composer={EmptyComponent}
        />
      </div>
    </div>
  );
}

export default SystemGPT;