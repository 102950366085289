import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Sign from './pages/Sign';
import Invitation from './pages/Invitation';

const App = () => {
  const isLoggedIn = localStorage.getItem('isLoggedInV5') === 'true';
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/invitation">
          {isLoggedIn ? <Redirect to="/" /> : <Invitation />}
        </Route>
        <Route exact path="/login">
          {isLoggedIn ? <Redirect to="/" /> : <Login />}
        </Route>
        <Route exact path="/login/:code">
          {isLoggedIn ? <Redirect to="/" /> : <Login />}
        </Route>
        <Route exact path="/login/:signid/:code">
          {isLoggedIn ? <Redirect to="/" /> : <Login />}
        </Route>
        <Route path="/:signid">
          {isLoggedIn ? <Redirect to="/" /> : <Sign />}
        </Route>
        <Route path="/">
          {!isLoggedIn ? <Redirect to="/login" /> : <Home />}
        </Route>
  

      </Switch>
    </BrowserRouter>
  );
}


export default App;