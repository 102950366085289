import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import './index.css';

import '@chatui/core/es/styles/index.less';
import '@chatui/core/dist/index.css';

const rootElement = document.getElementById('root');
createRoot(rootElement).render(<App />);
